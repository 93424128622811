import authHeaders from "../../../lib/authHeaders";
import { useState, useMemo } from "react";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import { Link, Box, Fade, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import AppBarButtonWrapper from "../../../components/AppBarButtonWrapper";
import { RemoteDataTable } from "@oddadigitalsystem/datatable";
import { useHistory, useLocation } from "react-router";

import searchNewSelect from "../../../lib/searchNewSelect";

import ProgressCell from "./ProgressCell";
import OperationsCell from "./OperationsCell";
import ItemCell from "./ItemCell";
import PlannerResponsibleComponent from "./PlannerResponsibleComponent";
import usePrevious from "../../../hooks/usePrevious";

import {
  getFiltersFromQueryString,
  getSortingFromQueryString,
} from "../../../lib/queryStringOperations";

const url = `${process.env.REACT_APP_VTS_API}/v1/production/progress_per_resource`;
const defaultLimit = 20;

const cellPadding = { padding: 10 };

const ProgressPerResource = () => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [tableRefreshed, refreshTable] = useState();

  const [responsible, setResponsible] = useState();
  const previousResponsible = usePrevious(responsible);

  const [planner, setPlanner] = useState();
  const previousPlanner = usePrevious(planner);

  const params = Object.fromEntries(new URLSearchParams(location.search));
  const initialFilters = Object.entries(
    getFiltersFromQueryString(params || {})
  ).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: { value, text: value } }),
    {}
  );
  const limit = params?.limit || defaultLimit;

  const initialSorting = getSortingFromQueryString(params || {});
  const isFilteredByProject = initialFilters?.job_no?.value;

  const fetchProjects = useMemo(() => {
    return searchNewSelect("project_number");
  }, []);

  const fetchData = (parameters) => {
    const mergedParams = {
      ...parameters,
      limit: isFilteredByProject ? 600 : defaultLimit,
    };
    const queryString = queryStringSerialize(mergedParams);
    history.replace({ search: queryString });

    setLoading(true);
    return fetch(`${url}?${queryString}`, {
      headers: authHeaders(),
    })
      .then((r) => {
        setLoading(false);
        return r.json();
      })
      .catch((e) => {
        setLoading(false);
        return e;
      });
  };

  const appBarHeight = document.getElementById("app-bar")?.clientHeight ?? 64;

  const tableRefreshDependency = `${limit}-${tableRefreshed}-${isFilteredByProject}`;

  const sortingFilteringProps = {
    disableSorting: !isFilteredByProject,
    disableFilter: !isFilteredByProject,
    customTooltip: !isFilteredByProject && "Please, select a project",
  };

  const title = initialFilters?.job_no?.value
    ? `Project: ${initialFilters.job_no.value}`
    : "Latest projects";

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading}>
        <IconButton
          onClick={() => refreshTable(new Date().getTime())}
          disabled={loading}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
      </AppBarButtonWrapper>
      <Box
        height={`calc(100vh - ${appBarHeight}px)`}
        style={{ overflow: "auto", backgroundColor: "white" }}
      >
        <Fade in timeout={200}>
          <div>
            <RemoteDataTable
              key={tableRefreshDependency}
              title={title}
              initialFilters={initialFilters}
              extraToolbarComponent={() => (
                <PlannerResponsibleComponent
                  isFilteredByProject={isFilteredByProject}
                  responsible={responsible}
                  planner={planner}
                />
              )}
              onResponseChange={(res) => {
                const newResponsible = res?.responsible;
                const newPlanner = res?.planner;
                isFilteredByProject &&
                  newResponsible !== previousResponsible &&
                  setResponsible(newResponsible);
                isFilteredByProject &&
                  newPlanner !== previousPlanner &&
                  setPlanner(newPlanner);
              }}
              enableSearch
              enableSorting
              enableFiltering
              singleSorting
              requestOptions={{
                rowsPerPage: limit,
              }}
              size="small"
              dataFetchCallback={fetchData}
              initialColumns={[
                {
                  name: "Project",
                  cellStyle: { ...cellPadding },
                  columnStyle: { ...cellPadding },
                  key: "job_no",
                  filterOptionsCallback: fetchProjects,
                },
                {
                  name: "Prod. order",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "production_order_no",
                  filterType: "text",
                  ...sortingFilteringProps,
                  format: (v) => (
                    <Link
                      color="inherit"
                      href={`/production/production-order?prod_order=${v}`}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/production/production-order?prod_order=${v}`
                        );
                      }}
                    >
                      {v}
                    </Link>
                  ),
                },
                {
                  name: "Item",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "item_no",
                  filterType: "number",
                  ...sortingFilteringProps,
                  format: (v, { ncr }) => <ItemCell item={v} ncr={ncr} />,
                },
                {
                  name: "Task",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "job_task_no",
                  filterType: "number",
                  ...sortingFilteringProps,
                },
                {
                  name: "PO Line",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "po_line",
                  filterType: "number",
                  ...sortingFilteringProps,
                },
                {
                  name: "Cust. item no",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "customer_part_no",
                  filterType: "text",
                  ...sortingFilteringProps,
                },
                {
                  name: "Drawing no",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "drawing_no",
                  filterType: "text",
                  ...sortingFilteringProps,
                },
                {
                  name: "Serial no",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "serial_no",
                  filterType: "text",
                  ...sortingFilteringProps,
                  format: (v) => (
                    <div>
                      {v?.map((number, i) => (
                        <div
                          key={i}
                          style={{
                            color: i % 2 ? "rgba(0, 0, 0, 0.5)" : undefined,
                            marginBottom: "3px",
                          }}
                        >{`${number}${
                          v?.length > 1 && v?.length - 1 !== i ? ", " : ""
                        }`}</div>
                      ))}
                    </div>
                  ),
                },
                {
                  name: "Description",
                  cellStyle: cellPadding,
                  columnStyle: { ...cellPadding, width: "15%" },
                  key: "description",
                  filterType: "text",
                  ...sortingFilteringProps,
                },
                {
                  name: "Status",
                  cellStyle: cellPadding,
                  columnStyle: cellPadding,
                  key: "status",
                  multipleFilter: true,
                  ...sortingFilteringProps,
                  filterOptionsCallback: () =>
                    new Promise((resolve) =>
                      resolve({
                        results: [
                          "Simulated",
                          "Planned",
                          "Firm Planned",
                          "Released",
                          "Finished",
                        ].map((val, i) => ({ value: i, text: val })),
                      })
                    ),
                },
                {
                  name: "Progress",
                  cellStyle: { ...cellPadding, margin: 2 },
                  columnStyle: { ...cellPadding },
                  key: "progress",
                  sortKey: "progress",
                  filterType: "text",
                  ...sortingFilteringProps,
                  format: (_v, row) => <ProgressCell routing={row?.routing} />,
                },
                {
                  name: "Operations",
                  cellStyle: { ...cellPadding, minWidth: 350 },
                  columnStyle: { ...cellPadding, minWidth: 350 },
                  key: "operations",
                  sortKey: "operation",
                  disableSorting: true,
                  disableFilter: true,

                  format: (_v, row) => (
                    <div style={{ width: "100%", height: "100%" }}>
                      <OperationsCell
                        routing={row?.routing}
                        productionOrderNumber={row?.production_order_no}
                        onClick={(url) => history.push(url)}
                      />
                    </div>
                  ),
                },
              ].map((column) => ({
                ...column,
                sort: initialSorting[column.key],
              }))}
            />
          </div>
        </Fade>
      </Box>
    </>
  );
};

export default ProgressPerResource;
