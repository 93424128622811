import PropTypes from "prop-types";
import { Autocomplete, Button, ListItemText, TextField } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Box } from "@mui/system";

const WorkCenterHeader = ({
  expandAll,
  onToggleExpandAll,
  loading,
  onWorkCenterClick,
  workCenters,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        alignContent: "space-between",
        mx: 1,
        mb: 2,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "30%",
          },
        }}
      />
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "40%",
          },
        }}
      >
        <Autocomplete
          options={workCenters ?? []}
          getOptionLabel={(option) =>
            `${option.work_center_number} - ${option.description}`
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <ListItemText
                primary={`${option.work_center_number} - ${option.description}`}
                secondary={`${option.address} • ${option.manager}`}
              />
            </li>
          )}
          onChange={(_, v) => onWorkCenterClick(v?.work_center_number)}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search work centers"
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          width: {
            xs: "100%",
            sm: "30%",
          },

          mt: isMobile ? 2 : 0,
        }}
      >
        <Button
          variant={expandAll ? "contained" : "outlined"}
          onClick={onToggleExpandAll}
          endIcon={expandAll ? <ExpandLess /> : <ExpandMore />}
          fullWidth={isMobile}
          sx={{
            ml: isMobile ? 0 : "auto",
            boxShadow: 0,
            "&:hover, &:focus": {
              boxShadow: 0,
            },
          }}
        >
          {expandAll ? "Minimize all" : "Expand all"}
        </Button>
      </Box>
    </Box>
  );
};

WorkCenterHeader.propTypes = {
  expandAll: PropTypes.bool,
  loading: PropTypes.bool,
  onToggleExpandAll: PropTypes.func,
  onWorkCenterClick: PropTypes.func,
  workCenters: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      description: PropTypes.string,
      manager: PropTypes.string,
      work_center_number: PropTypes.string,
    })
  ),
};

export default WorkCenterHeader;
