import { useEffect, useCallback } from "react";
import Navigation from "./Navigation";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import routes from "../../config/routes";
import footerRoutes from "../../config/footerRoutes";

import NotFound from "../../components/NotFound";
import IFrame from "../../components/IFrame";
import PageLayout from "../../components/PageLayout";
import GlobalLoading from "./GlobalLoading";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { onChangeTitle } from "../../redux/features/app-common-slice";

const currentRoute = (appRoutes, location) =>
  appRoutes.find((route) => route.path === location.pathname);

const App = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentUser = JSON.parse(localStorage.getItem("msToken"));
  const title = useAppSelector((state) => state["appCommon"].title);
  const appRoutes = routes();
  const footerMenu = footerRoutes();
  const appBarHeight = document.getElementById("app-bar")?.clientHeight || 64;
  let searchParams = new URLSearchParams(location.search);

  const currentTitle = currentRoute(appRoutes, location)?.title || "";

  const updateTitle = useCallback(() => {
    if (currentTitle) {
      dispatch(onChangeTitle(currentTitle));
      document.title = `${currentTitle} - Visual Tracking`;
    }
  }, [currentTitle, dispatch]);

  useEffect(() => {
    updateTitle();
  }, [updateTitle]);

  const handleNavigate = (path) => {
    history.push(path);
  };
  return (
    <>
      <Navigation
        onNavigate={handleNavigate}
        user={currentUser?.account?.name}
        email={currentUser?.account?.username}
        routes={appRoutes}
        footerRoutes={footerMenu}
        history={history}
        title={title}
        buttons={currentRoute(appRoutes, location)?.buttons || null}
      />

      <Switch>
        {appRoutes.map((page, i) => (
          <Route exact path={`${page?.path}`} key={i}>
            <PageLayout
              fullPage={page?.fullPage}
              componentType={typeof page?.component}
              maxWidth={page?.maxWidth}
            >
              {typeof page?.component === "function" && (
                <>
                  <GlobalLoading />
                  <page.component />
                </>
              )}
              {typeof page?.component === "string" && (
                <IFrame
                  accessToken={currentUser?.accessToken}
                  searchParams={searchParams}
                  url={page?.component}
                  title={page?.title}
                  appBarHeight={appBarHeight}
                />
              )}
            </PageLayout>
          </Route>
        ))}
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path={["/iframe/", "/iframe"]}>
          <IFrame
            onLoad={onChangeTitle}
            searchParams={searchParams}
            accessToken={currentUser?.accessToken}
            appBarHeight={appBarHeight}
          />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default App;
