import ItemNumberHighLight from "../../../components/HighLight/ItemNumberHighLight";
import NcrCell from "../../../components/NcrCell";

const ItemCell = ({ item, ncr }) => {
  return (
    <ItemNumberHighLight value={item} LinkProps={{ underline: "none" }}>
      <NcrCell ncr={ncr}>{item}</NcrCell>
    </ItemNumberHighLight>
  );
};

export default ItemCell;
