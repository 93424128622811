import { useState, useEffect, useCallback } from "react";

//States and actions
import { getData } from "./requestFunctions";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { onChangeLoadingState } from "../../../redux/features/app-common-slice";
//Local Components
import TableComponent from "./TableComponent";

import Collapse from "@mui/material/Collapse";

const ConnectedComponentsTable = ({ transitionTimeout }) => {
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(
    (state) => state["project/components"].projectFilter
  );
  const isProjectSelected = Boolean(selectedProject);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ rows: [], total: 0 });

  const handleLoadingLazyUpdate = useCallback(
    (loading, timeout = transitionTimeout * 2) =>
      setTimeout(() => {
        setLoading(loading);
        dispatch(onChangeLoadingState(loading));
      }, timeout),
    [dispatch, transitionTimeout]
  );

  const handleClearData = useCallback(
    (timeout = transitionTimeout * 2) => {
      setLoading(true);
      setTimeout(() => {
        setData({ rows: [], total: 0 });
      }, timeout);
    },
    [transitionTimeout]
  );

  useEffect(() => {
    if (isProjectSelected) {
      setLoading(true);
      getData(selectedProject.value)
        .then((r) => {
          setData(r);
          handleLoadingLazyUpdate(false);
        })
        .catch((e) => {
          //TODO: Improve error handling
          alert("fail to load");
          setLoading(false);
        });
    } else {
      handleClearData();
    }
  }, [
    handleClearData,
    handleLoadingLazyUpdate,
    isProjectSelected,
    selectedProject,
  ]);

  return (
    <>
      <Collapse
        in={!loading && isProjectSelected}
        timeout={{ enter: transitionTimeout * 3, exit: transitionTimeout }}
      >
        <div>
          <TableComponent projectTitle={selectedProject?.text} data={data} />
        </div>
      </Collapse>
    </>
  );
};

export default ConnectedComponentsTable;
