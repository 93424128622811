import { useState, useEffect } from "react";
import ConnectedComponentsTable from "./ConnectedComponentsTable";
import ProjectFilter from "./ProjectFilter";
import Container from "@mui/material/Container";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { Grow, Box } from "@mui/material";
import { onChangeLoadingState } from "../../../redux/features/app-common-slice";

const transitionTimeout = 500;
const Components = () => {
  const dispatch = useAppDispatch();
  //redux state
  const projectFilterSelected = useAppSelector((state) =>
    Boolean(state["project/components"].projectFilter)
  );

  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (projectFilterSelected) {
      setTimeout(() => {
        setShowTable(true);
      }, transitionTimeout + 25);

      dispatch(onChangeLoadingState(true));
    }
  }, [dispatch, projectFilterSelected]);

  return (
    <>
      <Container maxWidth="sm">
        <Grow
          in={!projectFilterSelected}
          timeout={{ enter: transitionTimeout, exit: transitionTimeout }}
        >
          <div>
            {!projectFilterSelected && (
              <Box mt={4}>
                <ProjectFilter />
              </Box>
            )}
          </div>
        </Grow>
      </Container>
      {showTable && (
        <ConnectedComponentsTable transitionTimeout={transitionTimeout} />
      )}
    </>
  );
};

export default Components;
