import React from "react";
import PropTypes from "prop-types";
import {
  Link,
  ListItemIcon,
  Tooltip,
  ListItemText,
  ListItemButton,
  List,
} from "@mui/material";

const HighLight = ({ choices, value, LinkProps, children }) => {
  return (
    <Tooltip
      arrow
      title={
        <List dense>
          {choices.map((item, i) => {
            const url = `${item.url}?${item.parameter}=${value ?? children}${
              item.extraParams || ""
            }`;
            return (
              <ListItemButton
                key={i}
                component="a"
                href={url}
                sx={{ borderRadius: 2 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(url, "_blank");
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            );
          })}
        </List>
      }
    >
      <Link color="#000" {...LinkProps}>
        {children}
      </Link>
    </Tooltip>
  );
};

HighLight.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      parameter: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      extraParams: PropTypes.string,
    })
  ).isRequired,
  // Props passed to the Link component wrapping children
  LinkProps: PropTypes.any,
};

export default HighLight;
