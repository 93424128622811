import PropTypes from "prop-types";
import { useState, forwardRef, useEffect, useMemo } from "react";
import {
  Box,
  ListItemText,
  List,
  ListItemButton,
  Card,
  ListItemIcon,
  Divider,
  IconButton,
  Collapse,
  Fade,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import Masonry from "@mui/lab/Masonry";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import useHover from "../../../../hooks/useHover";

const WorkCentersComponent = ({
  data,
  expandAll,
  onAdressClick,
  onManagerClick,
  onWorkCenterClick,
  managerHref,
  workCenterHref,
  addressHref,
  ListProps,
  MasonryProps,
}) => {
  const list = useMemo(() => Object.entries(data), [data]);

  return (
    <div id="work-center-component">
      <List disablePadding {...ListProps}>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          spacing={2}
          sx={{ margin: 0 }}
          {...MasonryProps}
        >
          {list.map(([address, secondLevel], index1) => {
            const secondLevelArray = Object.entries(secondLevel);
            const numberOfCategories = secondLevelArray?.length ?? 0;
            return (
              <Card
                id="level-1-container"
                key={`${index1}-${address}`}
                variant="outlined"
                sx={{ p: 1 }}
              >
                <CollapsableListItemButton
                  primary={address}
                  onClick={(e) => {
                    e.preventDefault();
                    onAdressClick(address);
                  }}
                  open={expandAll}
                  icon={<FolderCopyIcon sx={{ color: "primary.main" }} />}
                  divider
                  href={`${addressHref}${address}`}
                  secondary={`${numberOfCategories} categories`}
                >
                  <div id="level-2-container">
                    {secondLevelArray.map(([manager, workCenters], index2) => {
                      const numberOfWorkCenters = workCenters?.length ?? 0;
                      return (
                        <Box key={`${index2}-${manager}`} sx={{ my: 1 }}>
                          <CollapsableListItemButton
                            primary={manager}
                            secondary={`${numberOfWorkCenters} work centers`}
                            onClick={(e) => {
                              e.preventDefault();
                              onManagerClick(manager);
                            }}
                            open={expandAll}
                            icon={<PersonOutlineIcon />}
                            href={managerHref + manager}
                          >
                            <Box id="level-3-container">
                              {workCenters.map(
                                (
                                  { work_center_number, description },
                                  index3
                                ) => (
                                  <StyledListItemButton
                                    key={`${index3}-work-center-${work_center_number}-`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onWorkCenterClick(work_center_number);
                                    }}
                                    href={workCenterHref + work_center_number}
                                  >
                                    <ListItemText
                                      inset
                                      primaryTypographyProps={{
                                        sx: {
                                          fontSize: 12,
                                          fontWeight: "inherit",
                                        },
                                      }}
                                      primary={`${work_center_number} - ${description}`}
                                    />
                                  </StyledListItemButton>
                                )
                              )}
                            </Box>
                          </CollapsableListItemButton>
                        </Box>
                      );
                    })}
                  </div>
                </CollapsableListItemButton>
              </Card>
            );
          })}
        </Masonry>
      </List>
    </div>
  );
};

WorkCentersComponent.propTypes = {
  ListProps: PropTypes.object,
  MasonryProps: PropTypes.object,
  addressHref: PropTypes.string,
  data: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          address: PropTypes.string,
          description: PropTypes.string,
          manager: PropTypes.string,
          work_center_number: PropTypes.string,
        })
      )
    )
  ),
  expandAll: PropTypes.bool,
  managerHref: PropTypes.string,
  onAdressClick: PropTypes.func,
  onManagerClick: PropTypes.func,
  onWorkCenterClick: PropTypes.func,
  workCenterHref: PropTypes.string,
};

const CollapsableListItemButton = ({
  primary,
  secondary,
  onClick,
  divider,
  children,
  open,
  icon,
  href,
  ListItemProps,
}) => {
  const [internalOpen, setInternalOpen] = useState(open);
  const [hoverRef, isHovered] = useHover();

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <StyledListItemButton
          ref={hoverRef}
          href={href}
          onClick={onClick}
          {...ListItemProps}
        >
          {icon && (
            <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
          )}

          <ListItemText
            primary={primary}
            primaryTypographyProps={{ fontWeight: 500 }}
            secondary={
              isHovered && !internalOpen
                ? "Open queue list"
                : !internalOpen && secondary
            }
          />
        </StyledListItemButton>

        <IconButton
          size="small"
          onClick={() => setInternalOpen(!internalOpen)}
          sx={{ mx: 1 }}
        >
          {internalOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Fade in={divider && internalOpen} timeout={200}>
        <div>
          {divider && internalOpen && <Divider sx={{ my: 1, mx: -1 }} />}
        </div>
      </Fade>
      <Collapse in={internalOpen}>{children} </Collapse>
    </>
  );
};

CollapsableListItemButton.propTypes = {
  ListItemProps: PropTypes.object,
  children: PropTypes.node,
  divider: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

const StyledListItemButton = forwardRef(({ children, href, ...props }, ref) => (
  <ListItemButton
    component="a"
    ref={ref}
    href={href}
    {...props}
    sx={{
      "&:hover, &:focus": {
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
        color: "primary.main",
        borderRadius: 1,
      },
    }}
  >
    {children}
  </ListItemButton>
));

StyledListItemButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

export default WorkCentersComponent;
