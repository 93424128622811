import authHeaders from "../../../lib/authHeaders";
// @ts-ignore
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import ApiWeekColumns from "../../../models/production/queue-list/ApiWeekColumns";

const loadWeekColumns = async (filters: any): Promise<ApiWeekColumns> => {
  return fetch(
    `${
      process.env.REACT_APP_VTS_API
    }/v1/production/queue_list/week_columns?${queryStringSerialize(filters)}`,
    {
      headers: authHeaders(),
    }
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`Error ${response.status}. Failed to load the filters.`);
    }
  });
};

export default loadWeekColumns;
