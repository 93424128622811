import { useState } from "react";
import { Fade, IconButton } from "@mui/material";

import { useHistory } from "react-router";

import AppBarButtonWrapper from "../../../../components/AppBarButtonWrapper";
import useFetch from "../../../../hooks/useFetch";
import QueueListIndexComponent from "./WorkCentersComponent";
import FilterIcon from "@mui/icons-material/FilterList";

import WorkCenterHeader from "./WorkCenterHeader";

const QUEUE_LIST_URL = "/production/queue-list";
const ADDRESS_URL = `${QUEUE_LIST_URL}?filter[address]=`;
const MANAGER_URL = `${QUEUE_LIST_URL}?filter[name]=`;
const WORK_CENTER_URL = `${QUEUE_LIST_URL}?filter[work]=`;

const QueueListIndex = () => {
  const history = useHistory();
  const [expandAll, setExpandAll] = useState(true);
  const { data, loading } = useFetch({
    url: `${process.env.REACT_APP_VTS_API}/v1/production/queue_list/work_center_index`,
  });
  const isSupplierView = data?.supplier_view;

  const handleAddressClick = (value) => history.push(`${ADDRESS_URL}${value}`);

  const handleManagerClick = (value) => history.push(`${MANAGER_URL}${value}`);

  const handleWorkCenterClick = (value) =>
    history.push(`${WORK_CENTER_URL}${value}`);

  const handleRedirectToQueueList = () => history.push(QUEUE_LIST_URL);

  const handleToggleExpandAll = () => setExpandAll(!expandAll);

  return (
    <div>
      <AppBarButtonWrapper showSpinner={loading}>
        <IconButton onClick={handleRedirectToQueueList}>
          <FilterIcon />
        </IconButton>
      </AppBarButtonWrapper>

      {data && (
        <Fade in>
          <div>
            <WorkCenterHeader
              expandAll={expandAll}
              onWorkCenterClick={handleWorkCenterClick}
              onToggleExpandAll={handleToggleExpandAll}
              loading={loading}
              workCenters={data?.work_centers ?? []}
            />
            <QueueListIndexComponent
              ListProps={{ dense: true }}
              MasonryProps={
                isSupplierView ? { columns: { xs: 1, sm: 2 } } : undefined
              }
              data={data?.menu_list}
              expandAll={expandAll}
              onAdressClick={handleAddressClick}
              onManagerClick={handleManagerClick}
              onWorkCenterClick={handleWorkCenterClick}
              managerHref={MANAGER_URL}
              workCenterHref={WORK_CENTER_URL}
              addressHref={ADDRESS_URL}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

export default QueueListIndex;
