import { Divider, Box, useMediaQuery, useTheme } from "@mui/material";
import { Skeleton } from "@mui/material";

const Signature = ({ signature, name, date, loading, isApproved }) => {
  const signaturePrefix = isApproved ? "Approved by" : "Welder";

  if (loading)
    return (
      <Box display="flex">
        <Box my={1} mx="auto" width="100%">
          <Skeleton height={50} style={{ margin: 0 }} />
          <Skeleton height={2} style={{ marginBottom: 10 }} />
          <Skeleton style={{ marginTop: 0 }} />
        </Box>
      </Box>
    );
  else
    return (
      <Box textAlign="center">
        {signature && (
          <img
            height={50}
            width="auto"
            src={signature}
            alt="Signature"
            loading="lazy"
            style={{ marginBottom: 5 }}
          />
        )}
        <Divider />
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          {name ? (
            <div>
              <span>{`${signaturePrefix}: `}</span>
              <span style={{ fontWeight: 500 }}>{name}</span>
              <span>{`, ${new Date(date).toLocaleDateString()}`}</span>
            </div>
          ) : (
            "Not approved"
          )}
        </div>
      </Box>
    );
};

export const WeldApprovals = ({ welder, approval, loading, isApproved }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const flexStyle = { flex: isMobile ? 0 : 1, marginLeft: 20, marginRight: 20 };
  return (
    <Box display={isMobile ? "block" : "flex"} alignItems="baseline">
      <div style={flexStyle}>
        <Signature {...welder} loading={loading} />
      </div>
      {isApproved && (
        <div style={flexStyle}>
          <Signature {...approval} loading={loading} isApproved={isApproved} />
        </div>
      )}
    </Box>
  );
};
