/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import AddDocIcon from "@mui/icons-material/NoteAdd";
import debounce from "debounce-promise";
import { SearchBox } from "@oddadigitalsystem/vts-components/core";
import { fetchUrl } from "@oddadigitalsystem/vts-components/utils";

const MRBField = ({ number, newUrl, viewUrl }) => {
  if (number) {
    return <Link href={viewUrl}>{number}</Link>;
  } else {
    return (
      <Link href={newUrl} title="Add MRB">
        <AddDocIcon />
      </Link>
    );
  }
};

const MrbIndex = ({ page, limit }) => {
  const { accessToken } = JSON.parse(window.localStorage.getItem("msToken"));
  const [list, setList] = useState();
  const [loading, setLoading] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setLoading(true);
    fetch(
      fetchUrl(process.env.REACT_APP_VTS_API + "/v1/mrbs", {
        search,
        page: currentPage + 1,
        limit,
      }),
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((r) => r.json())
      .then((r) => {
        setList(r);
        setLoading(false);
      });
  }, [currentPage, search]);

  const applySearch = debounce((value) => setSearch(value), 1000);
  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Toolbar>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            MRB
          </Typography>
          {list && <SearchBox onChange={(e) => applySearch(e.target.value)} />}
        </Toolbar>
        <Table size="small" style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Task</TableCell>
              <TableCell>Project Name</TableCell>
              <TableCell>Customer PO</TableCell>
              <TableCell>Part Number</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>MRB</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell align="center" colSpan={9999}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              list &&
              list.data &&
              list.data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{row[0]}</TableCell>
                  <TableCell>{row[1]}</TableCell>
                  <TableCell>{row[3]}</TableCell>
                  <TableCell>{row[4]}</TableCell>
                  <TableCell>{row[5]}</TableCell>
                  <TableCell>{row[6]}</TableCell>
                  <TableCell>{row[7]}</TableCell>
                  <TableCell>
                    <MRBField
                      number={row[9]}
                      newUrl={fetchUrl("/documents/mrbs/new", {
                        project: row[0],
                        project_task: row[1],
                        line_number: row[2],
                      })}
                      viewUrl={`/documents/mrbs/${row[8]}`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {list && list.data && (
              <TableRow>
                <TablePagination
                  count={list.total}
                  onPageChange={(e, p) => setCurrentPage(p)}
                  page={currentPage}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[]}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

MrbIndex.defaultProps = {
  page: 0,
  limit: 10,
};

export default MrbIndex;
