import PropTypes from "prop-types";
import { RemoteDataTable } from "@oddadigitalsystem/datatable";

import FullpageTableScrollContainer from "../../../../components/FullpageTableScrollContainer";

export const NcrTable = ({ dataFetchCallback, onRowClick }) => (
  <FullpageTableScrollContainer>
    <RemoteDataTable
      onRowClick={onRowClick}
      dataFetchCallback={dataFetchCallback}
      initialColumns={[
        {
          name: "Created",
          key: "created_at",
          format: (v) => new Date(v).toLocaleDateString(),
          disableFilter: true,
        },
        {
          name: "NCR Identifier",
          key: "ncr_reference",
          disableFilter: true,
        },
        {
          name: "Title",
          key: "title",
          disableFilter: true,
        },
        {
          name: "Comments",
          key: "ncrComments",
          disableFilter: true,
        },

        {
          name: "Type",
          key: "ncr_type",
          disableFilter: true,
        },
        {
          name: "Level",
          key: "level",
          disableFilter: true,
        },
      ]}
      enableSorting
      enableFiltering
      enableSearch
      requestOptions={{
        rowsPerPage: 30,
      }}
      size="small"
    />
  </FullpageTableScrollContainer>
);

NcrTable.propTypes = {
  dataFetchCallback: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
