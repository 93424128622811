import { Box, Typography } from "@mui/material";

const PlannerResponsibleComponent = ({
  responsible,
  planner,
  isFilteredByProject,
}) => {
  if (!isFilteredByProject) return null;
  return (
    <Box display="inline-flex" mr={2} fontSize={14}>
      <Box mr={1} minWidth={100}>
        <Typography style={{ fontWeight: 500, fontSize: "inherit" }}>
          Responsible:
        </Typography>
        <Typography color="textSecondary" style={{ fontSize: "inherit" }}>
          {responsible ?? "None"}
        </Typography>
      </Box>
      <Box ml={1} minWidth={100}>
        <Typography style={{ fontWeight: 500, fontSize: "inherit" }}>
          Planner:
        </Typography>
        <Typography color="textSecondary" style={{ fontSize: "inherit" }}>
          {planner ?? "None"}
        </Typography>
      </Box>
    </Box>
  );
};

export default PlannerResponsibleComponent;
