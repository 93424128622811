import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useTooltipStyle = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: (props) => props.backgroundColor,
    color: (props) => props.fontColor,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    border: (props) =>
      props.borderColor ? `1px solid ${props.borderColor}` : "none",
    borderColor: (props) => props.borderColor,
  },
  arrow: {
    color: (props) => props.backgroundColor,

    "&::before": {
      backgroundColor: (props) => props.backgroundColor,
      border: (props) =>
        props.borderColor ? `1px solid ${props.borderColor}` : "none",
    },
  },
}));

const OperationTooltip = ({
  backgroundColor,
  fontColor,
  borderColor,
  children,
  ...props
}) => {
  const classes = useTooltipStyle({
    backgroundColor: backgroundColor,
    fontColor: fontColor,
    borderColor: borderColor,
  });
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

OperationTooltip.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  fontColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default OperationTooltip;
