import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router";
import { LinearProgress, Fade } from "@mui/material";
// import useFilters from "../lib/iframeUtils/useFilters";

const IFrame = ({ url, title, searchParams, accessToken, appBarHeight }) => {
  let location = useLocation();
  const locationPath =
    location.pathname.split("/iframe")?.[1] &&
    process.env.REACT_APP_VTS_ENDPOINT +
      location.pathname.split("/iframe")?.[1];
  const locationTitle = new URLSearchParams(location.search).get("title");

  const frameTitle =
    location?.state?.title || title || (locationPath && locationTitle);
  const frameUrl = location?.state?.url || url || locationPath;

  const [loading, setLoading] = useState(true);
  // const { getPageFilter, onUpdateFilter } = useFilters();

  useEffect(
    () => {
      const iframe = document.getElementById("railsvts")?.contentWindow;
      const receiveIframeMessage = (event) => {
        // if (event?.data?.reason === "UPDATE_FILTERS") {
        //   onUpdateFilter(event.data.location);
        // }

        if (
          event.origin === process.env.REACT_APP_VTS_ENDPOINT &&
          event.source === iframe &&
          event.data.reason === "LOADING"
        ) {
          setLoading(true);
        }
      };

      window.addEventListener("message", receiveIframeMessage);
      return () => {
        window.removeEventListener("message", receiveIframeMessage);
      };
    },
    [
      // onUpdateFilter
    ]
  );

  const tokenString = accessToken ? `&azure_token=${accessToken}` : "";

  if (frameTitle && frameUrl)
    return (
      <>
        <Fade in={loading}>
          <LinearProgress
            color="secondary"
            sx={{ position: "absolute", top: appBarHeight, left: 0, right: 0 }}
          />
        </Fade>

        <iframe
          id="railsvts"
          onLoad={() => {
            setLoading(false);
          }}
          src={`${frameUrl}?${searchParams}&layout=new${tokenString}`}
          title={frameTitle || ""}
          style={{
            width: "100%",
            height: `calc(99vh - ${appBarHeight}px`,
            border: "none",
          }}
        />
      </>
    );
  else return <Redirect to="/not-found" />;
};

export default IFrame;
