import { Typography, Box, Chip } from "@mui/material";

import { WeldText } from "./WeldText";
import DoneIcon from "@mui/icons-material/Done";
import ScheduleIcon from "@mui/icons-material/Schedule";

export const WeldHeader = ({
  title,
  projectNumber,
  customerName,
  customerPONumber,
  loading,
  isApproved,
  error,
}) => {
  const approvalPending = !error && !loading && !isApproved;
  return (
    <div>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" style={{ flex: 1 }}>
          {title}
        </Typography>
      </Box>
      <WeldText title="Project" value={projectNumber} loading={loading} />
      <WeldText title="Customer" value={customerName} loading={loading} />
      <WeldText
        title="Customer PO number"
        value={customerPONumber}
        loading={loading}
      />
      <Chip
        icon={
          loading ? undefined : isApproved ? <DoneIcon /> : <ScheduleIcon />
        }
        label={
          error
            ? "Error"
            : loading
            ? "Loading..."
            : isApproved
            ? "Approved"
            : "Approval pending"
        }
        variant="outlined"
        size="small"
        color={error || loading ? undefined : "primary"}
        style={{
          transition: "200ms",
          marginTop: 10,
          color: approvalPending ? "red" : undefined,
          borderColor: approvalPending ? "red" : undefined,
        }}
      />
    </div>
  );
};
