import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_KEY,
    redirectUri: process.env.PUBLIC_URL + "/auth",
    postLogoutRedirectUri: process.env.PUBLIC_URL,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
