import { Skeleton } from "@mui/material";
import { WeldText } from "./WeldText";

export const WeldBody = ({
  customerDrawingRevisionNumber,
  customerItemNumber,
  customerItemRevisionNumber,
  lotNumber,
  operationDescription,
  ourItemNumber,
  productionOrderDescription,
  serialNumber,
  customerDrawingNumber,
  loading,
}) => {
  const serialLabel = () => {
    if (serialNumber && !lotNumber) return "Serial number";
    if (lotNumber && !serialNumber) return "Lot number";
    else return "Serial / lot number";
  };
  return (
    <div>
      <WeldText
        title="Production order"
        value={productionOrderDescription}
        loading={loading}
      />
      <WeldText
        title="Operation"
        value={operationDescription}
        loading={loading}
      />
      <WeldText
        title="Cust. item no"
        value={customerItemNumber}
        loading={loading}
      />
      <WeldText
        title="item revision no"
        value={customerItemRevisionNumber}
        loading={loading}
      />
      <WeldText
        title="Cust drawing no"
        value={customerDrawingNumber}
        loading={loading}
      />
      <WeldText
        title="Cust drawing revision no"
        value={customerDrawingRevisionNumber}
        loading={loading}
      />

      <WeldText
        title={loading ? <Skeleton width={100} /> : serialLabel()}
        value={serialNumber ?? lotNumber ?? ""}
        loading={loading}
      />
    </div>
  );
};
