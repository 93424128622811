import React, { useEffect, useState } from "react";
import {
  CardContent,
  Typography,
  Container,
  Card,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Redirect } from "react-router-dom";

const NotFound = () => {
  const [redirect, setRedirect] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  if (redirect) return <Redirect to="/home" />;

  return (
    <div style={{ paddingTop: isMobile ? 0 : theme.spacing(40) }}>
      <Container maxWidth="sm">
        <Card>
          <CardContent style={{ height: "inherit", verticalAlign: "top" }}>
            <Typography variant="h6" color="textPrimary">
              Sorry
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              This page does not exist, or you do not have access to view this
              page. You will be redirected to the home page shortly.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default NotFound;
