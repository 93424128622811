import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  IconButton,
  Badge,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import LoadingButton from "@mui/lab/LoadingButton";

import { AutocompleteMultiselect } from "@oddadigitalsystem/vts-components/core";
import { fetchUrl } from "@oddadigitalsystem/vts-components/utils";
import authHeaders from "../../../lib/authHeaders";

const productionOrderStatuses = [
  { id: 0, text: "Pre Planned" },
  { id: 1, text: "Planned" },
  { id: 2, text: "In Progress" },
  { id: 3, text: "Released" },
  { id: 4, text: "Finished" },
];

// Remove finished status from initial state
const initialProdOrderStatuses = productionOrderStatuses.slice(0, -1);

const ProductionTimelineFilters = ({ onFiltersChange, ...props }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [allMachines, setAllMachines] = useState(false);
  const [loadingAllMachines, setLoadingAllMachines] = useState(false);
  const [internalFilters, setInternalFilters] = useState({
    status: initialProdOrderStatuses,
    routing_status: [],
    machine_center: [],
    work_center: [],
    item_number: [],
    available: [{ id: "", text: "All" }],
  });

  const filtersApplied = (filtersObject) =>
    Object.values(filtersObject).some((e) => e.length > 0)
      ? filtersObject
      : false;

  const handleClose = () => {
    if (!loadingAllMachines) {
      onFiltersChange(internalFilters);
      setFilterOpen(false);
    }
  };

  const loadAllMachines = async () => {
    setLoadingAllMachines(true);
    await Promise.all([
      fetch(
        fetchUrl(
          `${process.env.REACT_APP_VTS_API}/v1/select/machine_center_number`,
          { per: 999 }
        ),
        {
          headers: authHeaders(),
        }
      )
        .then((r) => r.json())
        .then((r) =>
          setInternalFilters((prev) => ({
            ...prev,
            machine_center: r.results,
          }))
        ),
      fetch(
        fetchUrl(
          `${process.env.REACT_APP_VTS_API}/v1/select/work_center_number`,
          { per: 999 }
        ),
        {
          headers: authHeaders(),
        }
      )
        .then((r) => r.json())
        .then((r) =>
          setInternalFilters((prev) => ({
            ...prev,
            work_center: r.results,
          }))
        ),
    ]);
    setLoadingAllMachines(false);
  };

  const handleFetchData = (url, term) =>
    fetch(fetchUrl(`${process.env.REACT_APP_VTS_API}/v1${url}`, { term }), {
      headers: authHeaders(),
    })
      .then((r) => r.json())
      .then(({ results }) => results);

  return (
    <>
      <IconButton onClick={() => setFilterOpen(true)}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!filtersApplied(internalFilters)}
        >
          <FilterListIcon />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={filterOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: 300 } }}
        {...props}
      >
        <Box display="flex" alignItems="center" m={2}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Filters
          </Typography>
          <LoadingButton onClick={handleClose} loading={loadingAllMachines}>
            Apply
          </LoadingButton>
        </Box>
        {!loadingAllMachines && (
          <Stack spacing={2} sx={{ px: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allMachines}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAllMachines(true);
                      loadAllMachines();
                    } else {
                      setAllMachines(false);
                      setInternalFilters((prev) => ({
                        ...prev,
                        machine_center: [],
                        work_center: [],
                      }));
                    }
                  }}
                  name="allMachines"
                />
              }
              label="All Machines and Work Centers"
            />
            <AutocompleteMultiselect
              size="small"
              fetchOptions={(term) =>
                handleFetchData("/select/machine_center_number", term, {
                  per: 999,
                })
              }
              label="Machines"
              chipKey="id"
              valueKey="id"
              value={internalFilters.machine_center}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, machine_center: v }))
              }
              disabled={allMachines}
            />
            <AutocompleteMultiselect
              size="small"
              fetchOptions={(term) =>
                handleFetchData("/select/work_center_number", term, {
                  per: 999,
                })
              }
              label="Work Centers"
              chipKey="id"
              valueKey="id"
              value={internalFilters.work_center}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, work_center: v }))
              }
              disabled={allMachines}
            />
            <AutocompleteMultiselect
              size="small"
              fetchOptions={(term) =>
                handleFetchData("/select/item_number", term)
              }
              label="Item Number"
              chipKey="id"
              valueKey="id"
              value={internalFilters.item_number}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, item_number: v }))
              }
            />
            <AutocompleteMultiselect
              size="small"
              label="Routing Statuses"
              value={internalFilters.routing_status}
              valueKey="id"
              options={[
                { id: 0, text: "Pre Planned" },
                { id: 1, text: "Planned" },
                { id: 2, text: "In Progress" },
                { id: 3, text: "Finished" },
              ]}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, routing_status: v }))
              }
            />
            <AutocompleteMultiselect
              size="small"
              label="Production Order Statuses"
              value={internalFilters.status}
              valueKey="id"
              options={productionOrderStatuses}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, status: v }))
              }
            />

            <FormControl>
              <FormLabel>Available?</FormLabel>
              <RadioGroup
                row
                value={internalFilters?.available[0]?.id}
                onChange={(e) => {
                  setInternalFilters((prev) => ({
                    ...prev,
                    available: [{ id: e.target.value, text: e.target.label }],
                  }));
                }}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
      </Drawer>
    </>
  );
};

ProductionTimelineFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
};

export default ProductionTimelineFilters;
