import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress, Link } from "@mui/material";
import { fetchUrl } from "@oddadigitalsystem/vts-components/utils";
import authHeaders from "../../../lib/authHeaders";

const DocumentCheck = ({ document, onValid }) => {
  const [results, setResults] = useState(false);
  const [loading, setLoading] = useState(true);
  const [documentId, setDocumentId] = useState();

  const notFound = !loading && results === 0;
  const valid = !loading && results === 1;
  const moreThan1 = !loading && results > 1;

  const url = fetchUrl(`${process.env.REACT_APP_VTS_API}/v1/documents`, {
    document_number: document.split("/")[0],
    document_revision: document.split("/")[1] || "",
    format: "json",
  });

  const urlDisplay = fetchUrl(`${process.env.REACT_APP_VTS_API}/v1/documents`, {
    document_number: document.split("/")[0],
    document_revision: document.split("/")[1] || "",
  });

  useEffect(() => {
    fetch(url, { headers: authHeaders() })
      .then((r) => r.json())
      .then((r) => {
        setResults(r.length);
        if (r.length === 1) {
          onValid(document, r[0].id);
          setDocumentId(r[0].id);
        }
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {documentId ? (
        <Link
          href={fetchUrl(`/iframe/documents/documents/${documentId}`, {
            title: "View Document",
          })}
          target="_blank"
        >
          {document}
        </Link>
      ) : (
        document
      )}
      {loading && <CircularProgress size={14} />}
      {valid && <DoneIcon style={{ color: "green" }} />}
      {notFound && (
        <a href={urlDisplay} target="_blank" rel="noreferrer">
          <CancelIcon style={{ color: "red" }} />
        </a>
      )}
      {moreThan1 && (
        <a href={urlDisplay} target="_blank" rel="noreferrer">
          <ErrorIcon style={{ color: "orange" }} />
        </a>
      )}
    </>
  );
};

DocumentCheck.propTypes = {
  document: PropTypes.string.isRequired,
  onValid: PropTypes.func,
};

export default DocumentCheck;
