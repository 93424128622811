import React from "react";
import { alpha, useTheme, Box } from "@mui/material";
import humanizeSnakeCase from "../../../lib/humanizeSnakeCase";
import OperationTooltip from "../progress-per-resource/OperationTooltip";
interface RoutingStatusCellProps {
  status:
    | "hold" // from comments
    | "late" // from comments
    | "done" // from comments
    | "pre_planned" // from NAV
    | "planned" // from NAV
    | "in_progress" // from NAV
    | "finished"; // from NAV
  value: string;
}

const RoutingStatusCell = ({ status, value }: RoutingStatusCellProps) => {
  const theme = useTheme();
  const colorPallete = {
    hold: "#C9302C",
    late: "#000",
    done: "#337AB7",
    pre_planned: "#FFFFFF",
    planned: "#A9A9A9",
    in_progress: "#5CB85C",
    finished: "#337AB7",
  };

  const backgroundColor = colorPallete[status]
    ? alpha(colorPallete[status], 0.9)
    : undefined;

  const color = colorPallete[status]
    ? theme.palette.getContrastText(colorPallete[status])
    : undefined;

  const capitalizedStatus = humanizeSnakeCase(status ?? "");

  const border = colorPallete[status]
    ? `1px solid ${colorPallete[status]}`
    : `1px solid rgba(224, 224, 224, 1)`;

  return (
    <OperationTooltip
      title={`${capitalizedStatus}`}
      backgroundColor={colorPallete[status]}
      fontColor={color}
      borderColor={undefined}
      arrow
      placement="left"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: backgroundColor,
          color: color,
          height: "100%",
          width: "100%",
          fontSize: "inherit",
          borderBottom: border,
        }}
      >
        {value}
      </Box>
    </OperationTooltip>
  );
};

export default RoutingStatusCell;
