import React, { useState } from "react";
import {
  Typography,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Button,
  CircularProgress,
  CardActionArea,
  Card,
} from "@mui/material";
import { read } from "xlsx";
import { useDropzone } from "react-dropzone";
import DocumentCheck from "./DocumentCheck";
import AddItemsToDocument from "./AddItemsToDocument";

const FMCImport = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState();

  const addDocumentsToList = (documents, itemNumber) => {
    documents.split(" ").forEach((spec) => {
      spec = spec.trim();
      if (spec) {
        setData((previousData) => {
          if (typeof previousData[spec] === "undefined") {
            return {
              ...previousData,
              [spec]: { items: [itemNumber], valid: false },
            };
          } else {
            return {
              ...previousData,
              [spec]: {
                ...previousData[spec],
                items: previousData[spec].items.concat(itemNumber),
              },
            };
          }
        });
      }
    });
  };

  const setSpecAsValid = (spec, id) =>
    setData((previousData) => ({
      ...previousData,
      [spec]: { ...previousData[spec], valid: true, id: id },
    }));

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: (files) => {
      setLoading(true);
      files[0].arrayBuffer().then((b) => {
        let excel = read(b, { type: "array" });
        let firstPageName = excel.SheetNames[0];
        let spreadsheet = excel.Sheets[firstPageName];
        let project = spreadsheet["A1"];
        project
          ? setProject(project["w"])
          : alert(
              "Spreadsheet has no project. Pay attention if this is correct."
            );
        let firstLine = 3;
        let lastLine = spreadsheet["!ref"].split(":")[1].match(/\d+$/)[0];
        for (
          let currentLine = firstLine;
          currentLine <= lastLine;
          currentLine++
        ) {
          let itemNumber = spreadsheet["A" + currentLine];
          if (itemNumber) {
            itemNumber = itemNumber["w"];
            addDocumentsToList(
              spreadsheet["C" + currentLine]["w"].replace(/\+/g, "") +
                "/" +
                spreadsheet["D" + currentLine]["w"],
              itemNumber
            );
            addDocumentsToList(
              spreadsheet["I" + currentLine]["w"] +
                "/" +
                spreadsheet["J" + currentLine]["w"],
              itemNumber
            );
            ["M", "N", "Q", "R"].forEach((column) => {
              if (spreadsheet[column + currentLine])
                addDocumentsToList(
                  spreadsheet[column + currentLine]["w"],
                  itemNumber
                );
            });
          } else {
            alert(`Line ${currentLine} has no item number. Skipping it.`);
          }
        }
        setLoading(false);
      });
    },
    onDropRejected: (e) => {
      console.error(e);
      alert("Invalid File");
    },
    multiple: false,
    preventDropOnDocument: true,
    accept: {
      "file/*": [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel.sheet.binary.macroenabled.12",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        "application/vnd.ms-excel.sheet.macroenabled.12",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
      ],
    },
  });

  return (
    <>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          {project && `Project: ${project}`}
        </Typography>
        {Object.keys(data).length > 0 && (
          <Button
            onClick={() => {
              setData({});
              setProject();
            }}
          >
            Start Over
          </Button>
        )}
      </Toolbar>
      {loading && <CircularProgress />}

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          {Object.keys(data).length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>Item Numbers</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(data)
                    .sort()
                    .map((document) => (
                      <TableRow key={document}>
                        <TableCell>
                          <DocumentCheck
                            document={document}
                            onValid={setSpecAsValid}
                          />
                        </TableCell>
                        <TableCell>{data[document].items.join(", ")}</TableCell>
                        <TableCell>
                          {data[document].valid && (
                            <AddItemsToDocument
                              documentId={data[document].id}
                              itemNumbers={data[document].items}
                              project={project}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "middle",
                textAlign: "center",
                height: "20vh",
                border: isDragActive ? "1px dashed" : undefined,
                borderColor: isDragActive ? "primary.main" : undefined,
                transitionDuration: "0.3s",
              }}
            >
              <CardActionArea
                {...getRootProps({ className: "dropzone" })}
                sx={{
                  height: "100%",
                  widht: "100%",
                  color: isDragActive ? "primary.main" : undefined,
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <input {...getInputProps()} />
                <Typography variant="body1" color="inherit">
                  Drag and drop the FMC file here, or click to select the file
                </Typography>
              </CardActionArea>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FMCImport;
