import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ResponsiveButtons } from "@oddadigitalsystem/vts-components/core";

import { useHistory } from "react-router-dom";
import logout from "../../lib/logout";

const HomeButtons = () => {
  //Redirect utilities
  const history = useHistory();
  const { railsUserId } = JSON.parse(localStorage.getItem("msToken"));

  const options = [
    {
      title: "Profile",
      onClick: (e) => history.push(`/profile/${railsUserId}`),
      icon: <AccountCircleIcon />,
    },
    {
      title: "Sign out",
      onClick: logout,
      icon: <ExitToAppIcon />,
    },
  ];

  return <ResponsiveButtons options={options} />;
};

export default HomeButtons;
