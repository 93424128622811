import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  MenuItem,
  TextField,
  Box,
  ListItemText,
  Fade,
} from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useSnackbar } from "notistack";
import EditableLine from "./EditableLine";

import { fetchUrl } from "@oddadigitalsystem/vts-components/utils";

import HeaderToolbar from "../../components/HeaderToolbar";

const onLoad = ({ machineCenter, startDate, endDate, accessToken }) =>
  fetch(
    fetchUrl(`${process.env.REACT_APP_VTS_API}/v1/employees_per_day`, {
      filter: {
        machine_center: machineCenter,
        after: startDate.toISOString(),
        before: endDate.toISOString(),
      },
      per: 1000,
    }),
    { headers: { authorization: `Bearer ${accessToken}` } }
  )
    .then((r) => {
      if (r.status >= 400) throw new Error("Failed to fetch");
      else return r.json();
    })
    .then(({ data }) => data)
    .catch((e) => {
      throw new Error(e);
    });

const EmployeesPerDay = ({ machineCenterOptions }) => {
  const userInfo = JSON.parse(window.localStorage.getItem("msToken"));
  const { accessToken } = userInfo || {};
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(
    moment().add(12, "month").startOf("month")
  );
  const [dateErrors, setDateErrors] = useState({
    startDate: null,
    endDate: null,
  });
  const [machineCenter, setMachineCenter] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const datesAreValid =
    moment(startDate, "strict").isValid() &&
    moment(endDate, "strict").isValid() &&
    !dateErrors.startDate &&
    !dateErrors.endDate;

  const fieldsAreFilled = machineCenter && datesAreValid;

  //Callback to prevent re-render
  const handleLoadData = useCallback(
    (...params) => {
      setLoading(true);
      return onLoad(...params)
        .then((data) => {
          setData(data);
        })
        .catch((e) => {
          enqueueSnackbar(e.toString(), { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (machineCenter && datesAreValid) {
      setData([]);
      setLoading(true);
      handleLoadData({ startDate, endDate, machineCenter, accessToken });
    }
  }, [
    startDate,
    endDate,
    datesAreValid,
    machineCenter,
    handleLoadData,
    accessToken,
  ]);

  const handleCreate = (params) => {
    fetch(fetchUrl(`${process.env.REACT_APP_VTS_API}/v1/employees_per_day`), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ employees_per_day: params }),
    })
      .catch((e) => {
        enqueueSnackbar(e.toString());
      })
      .finally(() => {
        handleLoadData({ startDate, endDate, machineCenter, accessToken });
      });
  };

  const handleUpdate = (id, params) => {
    fetch(
      fetchUrl(`${process.env.REACT_APP_VTS_API}/v1/employees_per_day/${id}`),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ employees_per_day: params }),
      }
    )
      .catch((e) => {
        enqueueSnackbar(e.toString(), { variant: "error" });
      })
      .finally(() => {
        handleLoadData({ startDate, endDate, machineCenter, accessToken });
      });
  };

  const dates = [];
  for (
    let i = startDate?.clone();
    datesAreValid && i <= endDate;
    i.add(1, "month")
  ) {
    dates.push(i.clone());
  }

  return (
    <>
      <HeaderToolbar title="Work center load" loading={loading}>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item xs={12} lg={6}>
            <TextField
              label="Machine Center"
              select
              value={machineCenter}
              color="secondary"
              fullWidth
              onChange={(e) => setMachineCenter(e.target.value)}
              size="small"
              required
              inputProps={{
                sx: { minWidth: { sm: undefined, lg: 200 }, maxWidth: 200 },
              }}
            >
              <MenuItem value="">None</MenuItem>
              {machineCenterOptions.map((option) => (
                <MenuItem value={option[1]} key={option[1]}>
                  {option[1]} - {option[0]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} lg={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              views={["year", "month"]}
              maxDate={endDate}
              onError={(err) =>
                setDateErrors((prev) => ({ ...prev, startDate: err }))
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              views={["year", "month"]}
              minDate={startDate}
              onError={(err) =>
                setDateErrors((prev) => ({ ...prev, endDate: err }))
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </Grid>
        </Grid>
      </HeaderToolbar>
      <Box py={2}>
        {!loading && fieldsAreFilled ? (
          <Fade in>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "30%" }}>Month</TableCell>
                    <TableCell style={{ width: "27.5%" }}>Employees</TableCell>
                    <TableCell style={{ width: "27.5%" }}>Overtime</TableCell>
                    <TableCell style={{ width: "15%" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dates.map((date, i) => {
                    let record = data.find(
                      (e) => e.day === date.format("YYYY-MM-DD")
                    );
                    return (
                      <EditableLine
                        key={i}
                        record={record}
                        date={date}
                        machineCenter={machineCenter}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Fade>
        ) : (
          <>
            <Fade in>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <ListItemText
                  style={{ textAlign: "center" }}
                  primary={!loading ? "Select a machine center" : "Loading..."}
                  secondary={!loading ? "And a date range" : ""}
                />
              </Box>
            </Fade>
          </>
        )}
      </Box>
    </>
  );
};

EmployeesPerDay.defaultProps = {
  machineCenterOptions: [
    ["MRB-UTARBEIDELSE", "O140"],
    ["MONTERING (MEKANISK ARBEID)", "O142"],
    ["HANDARBEID", "O148"],
    ["HANDARBEID SVEIS", "O149"],
    ["DOKUMENT KONTROLL", "O153"],
    ["TESTING", "O164"],
    ["CNC-dreiebenk PUMA 700XLY", "O400"],
    ["CNC-dreiebenk PUMA 5100 LYB", "O401"],
    ["DREIEBENK VERT. SCHIESS 27 DE", "O404"],
    ["DM 125 DF (NORD)", "O410"],
    ["DM 160 DF M_U-AKSE (NY)", "O412"],
    ["BORE-FRESEVERK TOSHIBA", "O427"],
    ["DREIEBENK VDF Ø500", "O461"],
    ["BOREMASKIN OERLIKON RADIAL", "O481"],
    ["FRESEMASKIN MILARCRON", "O483"],
    ["FRESEMASKIN SAJO HBF 450", "O486"],
    ["OERLIKON FRESEMASKIN", "O487"],
    ["CU10 FRESEMASKIN", "O488"],
    ["HEAT TREATMENT", "O515"],
    ["MÅLEMASKIN ZEISS", "O520"],
    ["METRONOR", "O521"],
    ["BANDSAG (FINGERSPLITTING)", "O525"],
    ["RØRKUTTING MED FUGING", "O526"],
    ["SAG BOMAR Ø520", "O527"],
    ["VASKEMASKIN TAJO", "O541"],
    ["PULSMIG", "O560"],
    ["HW/CW TIG", "O561"],
    ["TIG MANUELL", "O564"],
    ["ORBITAL TIG (LARVIK)", "O567"],
    ["ETR", "O569"],
    ["COMPACT CELLE", "O570"],
    ["POLYSOUDE - TIGER", "O591"],
    ["PULVERBUE", "O595"],
    ["PTA sveising", "O596"],
    ["FERRITMÅLING", "O600"],
    ["MONITORERING AV SVEIS", "O601"],
    ["HARDHETSKONTROLL", "O602"],
    ["NDT-PAUT ULTRALYD (PHASEARRAY)", "O603"],
    ["NDT-MT", "O605"],
    ["NDT-UT", "O606"],
    ["NDT-VT", "O607"],
    ["NDT-RT", "O608"],
    ["NDT-PT", "O609"],
    ["MEKANISK KONTROLL", "O610"],
    ["NDT-PMI", "O611"],
  ],
};

export default EmployeesPerDay;
