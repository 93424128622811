import { useState, useCallback, useRef, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
  Collapse,
  Link,
  Chip,
  Paper,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDropzone } from "react-dropzone";

import { useFormikContext, FieldArray } from "formik";
import { TransitionGroup } from "react-transition-group";

import DeleteAttatchmentMenu from "./DeleteAttachmentMenu";
import { formatBytes } from "./formatBytes";
import FileTypeIcon from "./FileTypeIcon";
import FileDropOverlay from "./FileDropOverlay";
import { useSnackbar } from "notistack";
import { nudgeAnimation } from "../common/nudgeAnimation";
import { ToggleIcon } from "@oddadigitalsystem/vts-components/core";

const maxFileSize = 26214400;

const parseFileType = (type) => {
  if (type) {
    return type.split("/").pop().toUpperCase();
  } else return "Unknown";
};

const errorHandler = (errors, enqueueSnackbar) =>
  errors?.map((error) =>
    enqueueSnackbar(
      `${error.file.name} is to large, max file size is:  ${formatBytes(
        maxFileSize
      )}`,
      {
        variant: "error",
      }
    )
  );

const Attachments = ({ disabled, isDraggingOutside }) => {
  const { values, setFieldValue } = useFormikContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const scrollRef = useRef(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue("attachments", [...values.attachments, ...acceptedFiles]);
    },
    [setFieldValue, values]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: maxFileSize,
    onDropRejected: (errors) => errorHandler(errors, enqueueSnackbar),
  });

  const hasFiles = Boolean(values?.attachments?.length > 0);

  const amountOfNewFiles =
    values?.attachments.filter((file) => {
      if (file instanceof File) return file;
      else return undefined;
    })?.length ?? 0;

  useEffect(() => {
    isDraggingOutside && buttonRef.current && setAnchorEl(buttonRef.current);
  }, [isDraggingOutside]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      });
    }, 400);
    return () => clearTimeout(timer);
  }, [onDrop]);

  return (
    <>
      <Badge
        badgeContent={values?.attachments?.length}
        color="primary"
        overlap="circular"
      >
        <IconButton
          onClick={handleClick}
          color={anchorEl || isDraggingOutside ? "primary" : "default"}
          disabled={disabled}
          onDragOver={handleClick}
          ref={buttonRef}
          size="large"
        >
          <ToggleIcon
            on={isDraggingOutside}
            onIcon={<AttachFileIcon color="primary" />}
            offIcon={<AttachFileIcon color="action" />}
            sx={nudgeAnimation(isDraggingOutside)}
          />
        </IconButton>
      </Badge>
      <Popover
        elevation={isDragActive ? 10 : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div
          style={{
            width: 330,
            minHeight: 330,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            transitionDuration: "0.2s",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <FileDropOverlay
            isDragActive={isDragActive}
            hasFiles={hasFiles}
            isDraggingOutside={isDraggingOutside}
          />

          {hasFiles && (
            <>
              <Box mx={2} my={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ flex: 1 }}>
                  Attachments
                </Typography>
                {amountOfNewFiles > 0 && (
                  <Typography color="secondary" variant="subtitle2">
                    {amountOfNewFiles} new
                  </Typography>
                )}
              </Box>
              <Divider />
            </>
          )}

          {values?.attachments.length > 0 && (
            <List
              component="nav"
              dense
              disablePadding
              style={{
                maxHeight: 500,
                overflowX: "auto",
                flexGrow: 1,
              }}
            >
              <FieldArray name="attachments">
                {({ remove }) => (
                  <TransitionGroup>
                    {values?.attachments?.map((file, i) => (
                      <Collapse key={file?.path || file?.id}>
                        <ListItem
                          button
                          divider
                          key={i}
                          href={file.src || URL.createObjectURL(file)}
                          target="blank"
                          component={Link}
                          color="inherit"
                          download={file.path}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ListItemIcon style={{ minWidth: 42 }}>
                            <Badge
                              invisible={
                                Boolean(file.src) ||
                                isDraggingOutside ||
                                isDragActive
                              }
                              variant="dot"
                              color="primary"
                            >
                              <FileTypeIcon type={file.type} />
                            </Badge>
                          </ListItemIcon>

                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="body1"
                                style={{ fontSize: 14 }}
                                noWrap
                              >
                                {file.name}
                              </Typography>
                            }
                            secondary={
                              <Box mt={0.2} style={{ fontSize: 12 }}>
                                <Chip
                                  label={parseFileType(file.type)}
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "inherit",
                                    maxWidth: 100,
                                  }}
                                />
                                <Chip
                                  label={formatBytes(file.size)}
                                  size="small"
                                  variant="outlined"
                                  style={{ fontSize: "inherit" }}
                                />
                              </Box>
                            }
                          />

                          <ListItemSecondaryAction>
                            <DeleteAttatchmentMenu
                              onDelete={() => {
                                remove(i);
                                file.id &&
                                  setFieldValue("delete_attachments", [
                                    ...values?.delete_attachments,
                                    file.id,
                                  ]);
                              }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Collapse>
                    ))}
                    <div
                      ref={scrollRef}
                      id="scrollanchor"
                      style={{ height: 5 }}
                    />
                  </TransitionGroup>
                )}
              </FieldArray>
            </List>
          )}

          {hasFiles && (
            <Box mt="auto" component={Paper} elevation={3}>
              <Divider />
              <Button
                color="secondary"
                fullWidth
                disabled={isDragActive}
                style={{ borderRadius: 0 }}
              >
                Browse
              </Button>
            </Box>
          )}
        </div>
      </Popover>
    </>
  );
};

export default Attachments;
