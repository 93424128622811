import { useState } from "react";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import { Fab, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import authHeaders from "../../../lib/authHeaders";
import { useSnackbar } from "notistack";
import NcrForm from "./form/NcrForm";
import { NcrTable } from "./table/NcrTable";
import { serialize } from "object-to-formdata";

const NewNcrFab = ({ onClick, formOnly }) => {
  if (formOnly) {
    return (
      <IconButton onClick={onClick} size="large">
        <AddCircleIcon />
      </IconButton>
    );
  } else
    return (
      <Fab
        onClick={onClick}
        color="secondary"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 999,
        }}
      >
        <AddIcon />
      </Fab>
    );
};

const generateKey = () => "ncrTable-" + new Date().getTime();

const url = `${process.env.REACT_APP_VTS_API}/v1/ncr`;

const Ncr = ({ formOnly, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [tableKey, reloadTable] = useState("ncrTable");

  const errorHandler = (error) => {
    try {
      error.json();
      return error.json().then((body) => {
        enqueueSnackbar(`${body.status}: ${body.message}`, {
          variant: "error",
        });
      });
    } catch (e) {
      enqueueSnackbar("Failed to load data", {
        variant: "error",
      });
    }
  };

  const handleReloadTable = () => reloadTable(generateKey());

  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
    setFormData({});
  };

  const handleCreate = (formData) => {
    return fetch(url, {
      method: "POST",
      headers: authHeaders({ defaultContentType: true }),
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          typeof onSubmit === "function" && onSubmit();
          return response.json();
        }
      })
      .catch(errorHandler);
  };

  const handleUpdate = (formData, id) => {
    return fetch(`${url}/${id}`, {
      method: "PUT",
      headers: authHeaders({ defaultContentType: true }),
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch(errorHandler);
  };

  const handleSubmit = (form) => {
    const formData = serialize(form);

    if (form.id) {
      handleUpdate(formData, form.id);
    } else {
      handleCreate(formData);
    }
  };

  const handleDelete = (id) => {
    fetch(`${url}/${id}`, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch(errorHandler);
  };

  const handleEdit = (rowData, _e) => {
    const { id } = rowData;
    handleOpenForm();
    setFormLoading(true);
    fetch(`${url}/${id}`, {
      headers: authHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setFormData(data);
        setFormLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setFormOpen(false);
        setFormLoading(false);
      });
  };

  const fetchTableData = (params) =>
    fetch(`${url}?${queryStringSerialize(params)}`, { headers: authHeaders() })
      .then((r) => r.json())
      .catch((error) => {
        errorHandler(error);
        return error;
      });

  if (formOnly)
    return (
      <>
        <NewNcrFab onClick={handleOpenForm} formOnly={formOnly} />
        <NcrForm
          open={formOpen}
          onClose={handleCloseForm}
          data={formData}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          loading={formLoading}
        />
      </>
    );
  else
    return (
      <>
        <NewNcrFab onClick={handleOpenForm} />
        <NcrForm
          open={formOpen}
          onClose={handleCloseForm}
          data={formData}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          loading={formLoading}
        />
        <NcrTable
          key={tableKey}
          onRowClick={handleEdit}
          dataFetchCallback={fetchTableData}
        />
      </>
    );
};

export default Ncr;
