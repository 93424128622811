import { Grid, Typography } from "@mui/material";
import QRCode from "qrcode";
import { FunctionComponent } from "react";
import "./QRReceiptLabelStyle.css";

interface QRCodeProps {
  itemNo: string;
  itemDesc: string;
  url: string;
  serialNumber?: string;
  customerItemNumber: string;
  customerDrawingNumber: string;
  lotNumber?: string;
  operationNumber: string;
  projectNumber: string;
  projectDescription: string;
  projectTaskNumber: string;
  purchaseOrder: string;
  purchaseLine: string;
  quantity: string;
}

const labelStyles = {
  height: "2.5in",
  padding: 8,
  width: '80%',
  marginLeft: '20%'
};

const QRReceiptLabel: FunctionComponent<QRCodeProps> = (props: QRCodeProps) => {
  let dataUrl: string;
  const generateQRCode = (text: string) => {
    QRCode.toDataURL(
      text,
      { scale: 2, errorCorrectionLevel: "H" },
      (_, url) => {
        dataUrl = url;
      }
    );
    return dataUrl;
  };

  return (
    <Grid
      container
      style={labelStyles}
      direction="row"
      alignContent="center"
      className="print onprint"      
    >
      <Grid item xs={12}>
        {props.projectDescription && props.projectNumber && (
          <Typography>
            Project:{" "}
            <strong>
              {props.projectNumber} / {props.projectDescription}
            </strong>
          </Typography>
        )}
        <Typography>
          Description:{" "}
          <strong style={{ textTransform: "uppercase" }}>
            {props.itemDesc}
          </strong>
        </Typography>

        <Typography>
          Item No.: <strong>{props.itemNo}</strong>
        </Typography>

        {props.serialNumber && (
          <Typography>
            Serial No.: <strong>{props.serialNumber}</strong>
          </Typography>
        )}

        {props.customerDrawingNumber && (
          <Typography>
            Drawing No.: <strong>{props.customerDrawingNumber}</strong>
          </Typography>
        )}

        <Typography>
          Customer Item No.: <strong>{props.customerItemNumber}</strong>
        </Typography>
      </Grid>
      <Grid xs={8} item>
        {props.projectTaskNumber && (
          <Typography>
            Task No.: <strong>{props.projectTaskNumber}</strong>
          </Typography>
        )}

        {props.operationNumber && (
          <Typography>
            Operation No.: <strong>{props.operationNumber}</strong>
          </Typography>
        )}

        <Typography>
          Purchase Order: <strong>{props.purchaseOrder}</strong>
        </Typography>

        <Typography>
          Purchase Line: <strong>{props.purchaseLine}</strong>
        </Typography>

        <Typography>
          Quantity: <strong>{props.quantity}</strong>
        </Typography>

        {props.lotNumber && (
          <Typography>
            Lot No.: <strong>{props.lotNumber}</strong>
          </Typography>
        )}
      </Grid>
      <Grid xs={4} item alignContent="flex-end">
        <img src={generateQRCode(props.url)} alt="qrcode" />
      </Grid>
    </Grid>
  );
};
export default QRReceiptLabel;
