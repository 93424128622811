import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  NavigationDrawer,
  TopBar,
} from "@oddadigitalsystem/vts-components/core";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.svg";
import vtsLogo from "../../images/vtsLogo.png";
import { ListItemAvatar, ListItem, Avatar, ListItemText } from "@mui/material";
import "./Navigation.css";

const Navigation = ({
  title,
  buttons,
  routes,
  footerRoutes,
  history,
  user,
  email,
  onNavigate,
}) => {
  const { railsUserId } = JSON.parse(localStorage.getItem("msToken"));
  const { t } = useTranslation();
  const Buttons = buttons ? buttons : () => null;
  const [openNavDrawer, setOpenNavDrawer] = useState(false);
  const handleToggleNavDrawer = () => setOpenNavDrawer((state) => !state);

  return (
    <>
      <TopBar
        title={title}
        openMenu={handleToggleNavDrawer}
        className="no-print"
        buttons={() => <Buttons />}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          color: "black",
          backdropFilter: "blur(20px)",
        }}
      />
      <NavigationDrawer
        onNavigate={onNavigate}
        forcePin
        enablePinning
        enableMostVisited
        open={openNavDrawer}
        list={routes}
        footerList={footerRoutes}
        history={history}
        onToggle={handleToggleNavDrawer}
        enableFilter
        filterOptions={{
          label: "Search...",
          color: "secondary",
        }}
        topLogo={{
          src: logo,
          href: "/",
          alt: "Odda Technology",
          height: 40,
          mt: 2,
          mb: 1,
        }}
        switcherProps={{
          boxProps: { my: 1 },
          selectedPlace: t("Visual Tracking"),
          onChangePlace: (obj) => {
            window.location.href = obj.url;
          },
          places: [
            {
              name: t("Visual Tracking"),
              url: "/home",
            },
            {
              name: t("Visual Quality"),
              url: "https://vqs.oddatechnology.no/",
            },
            {
              name: t("Visual Document"),
              url: "https://vds.oddatechnology.no/",
            },
            {
              name: t("Visual Registration"),
              url: "https://app.visualregistration.no/hourPage",
            },
            {
              name: t("Dynamics NAV"),
              url: "https://otserver08.oddatechnology.no/NAV_ODS_2016",
            },
            {
              name: t("Portal"),
              url: "https://portal.oddatechnology.no/",
            },
            {
              name: t("Visual Tracking V2"),
              url: "https://newvts.oddatechnology.no/",
            },
          ],
        }}
        bottomComponent={() => (
          <ListItem
            button
            onClick={() => {
              history.push(`/profile/${railsUserId}`);
              setOpenNavDrawer(false);
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemAvatar style={{ backgroundColor: "transparent" }}>
                <Avatar style={{ backgroundColor: "transparent" }}>
                  <img height={30} width={30} src={vtsLogo} alt="VTS logo" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user}
                secondary={email}
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </span>
          </ListItem>
        )}
      />
    </>
  );
};

export default Navigation;

Navigation.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.elementType,
  onNavigate: PropTypes.func,
};
