import { QueryStringParams } from "./iframeUtils/types";

const getFilterKeyName = (key: string): string => key.split(/filter\[|]/)[1];
const getSortKeyName = (key: string): string => key.split(/sort\[|]/)[1];

export const getFiltersFromQueryString = (
  params: QueryStringParams
): QueryStringParams =>
  Object.keys(params)
    .filter((key) => key.includes("filter"))
    .reduce((previousValues: QueryStringParams, filterKey: string) => {
      previousValues[getFilterKeyName(filterKey)] = params[filterKey];
      return previousValues;
    }, {});

export const getSortingFromQueryString = (
  params: QueryStringParams
): QueryStringParams =>
  Object.keys(params)
    .filter((key) => key.includes("sort"))
    .reduce((previousValues: QueryStringParams, filterKey: string) => {
      previousValues[getSortKeyName(filterKey)] = params[filterKey];
      return previousValues;
    }, {});
