import { Fade, Box, ListItemText } from "@mui/material";
import React from "react";

const HideIfNoFilters = ({ filters, children }) => {
  return Object.keys(filters).length === 0 ? (
    <Fade in>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <ListItemText
          style={{ textAlign: "center" }}
          primary="No filters selected"
          secondary="Please select a filter to see the results"
        />
      </Box>
    </Fade>
  ) : (
    children
  );
};

export default HideIfNoFilters;
