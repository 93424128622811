import PropTypes from "prop-types";
import { Box, Typography, IconButton, Divider } from "@mui/material";

import { useFormikContext } from "formik";
import { Skeleton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Delete from "@mui/icons-material/Delete";
import Attachments from "../attachments/Attachments";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const HorizontalDivider = () => (
  <Divider
    flexItem
    orientation="vertical"
    style={{
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 10,
    }}
  />
);

export const NcrHeaderLoadingTemplate = () => {
  return (
    <Box pt={1} px={2} pb={1} display="flex" alignItems="center">
      <Box flex={1}>
        <Typography variant="h6" sx={{ fontSize: 18 }}>
          <Skeleton width={200} />
        </Typography>

        <Skeleton width={300} />
      </Box>

      <IconButton disabled size="large">
        <AttachFileIcon />
      </IconButton>
      <HorizontalDivider />
      <IconButton disabled size="large">
        <Delete />
      </IconButton>
      <IconButton disabled size="large">
        <CancelIcon />
      </IconButton>
    </Box>
  );
};

const NcrHeader = ({
  ncrNumber,
  createdAt,
  onClose,
  onDelete,
  loading,
  isDragActive,
}) => {
  const { values, isSubmitting } = useFormikContext();
  return (
    <Box pt={1} px={2} pb={1} display="flex" alignItems="center">
      <Box flex={1}>
        <Typography variant="h6" sx={{ fontSize: 18 }}>
          {loading ? <Skeleton width={200} /> : values?.title || "NCR"}
        </Typography>
        {loading ? (
          <Skeleton width={300} />
        ) : (
          <span>
            {ncrNumber && (
              <Typography
                color="primary"
                component="span"
                sx={{ fontSize: 14 }}
              >
                {`#${ncrNumber} `}
              </Typography>
            )}

            <Typography
              color="textSecondary"
              component="span"
              sx={{ fontSize: 14 }}
            >
              {` ${!ncrNumber ? "" : "•"} ${new Date(
                createdAt
              ).toLocaleDateString()} `}
            </Typography>
          </span>
        )}
      </Box>

      <Attachments
        disabled={loading || isSubmitting}
        isDraggingOutside={isDragActive}
      />
      <HorizontalDivider />
      {values?.id && (
        <IconButton
          onClick={onDelete}
          disabled={loading || isSubmitting}
          size="large"
        >
          <Delete />
        </IconButton>
      )}
      <IconButton
        onClick={onClose}
        disabled={loading || isSubmitting}
        size="large"
      >
        <CancelIcon />
      </IconButton>
    </Box>
  );
};

export default NcrHeader;

NcrHeader.propTypes = {
  createdAt: PropTypes.string,
  loading: PropTypes.bool,
  ncrNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.string,
};
