import React, { useState } from "react";
import { RemoteDataTable } from "@oddadigitalsystem/datatable";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import authHeaders from "../../../lib/authHeaders";
import PurchaseHighLight from "../../../components/HighLight/PurchaseHighLight";
import ItemNumberHighLight from "../../../components/HighLight/ItemNumberHighLight";
import ReceiptControlButtons from "./ReceiptControlButtons";
import { useHistory, useLocation } from "react-router";
import { pickBy } from "lodash";
import queryString from "query-string";
import prepareFilter from "../../../lib/prepareFilter";
import searchNewSelect from "../../../lib/searchNewSelect";
import FullpageTableScrollContainer from "../../../components/FullpageTableScrollContainer";

const ReceiptControl = () => {
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let location = useLocation();

  const initialFilters = prepareFilter(queryString.parse(location.search));

  return (
    <>
      <ReceiptControlButtons
        loading={loading}
        filters={queryString.parse(location.search)}
      />
      <FullpageTableScrollContainer>
        <RemoteDataTable
          initialFilters={initialFilters}
          size="small"
          enableFiltering
          enableSorting
          requestOptions={{
            rowsPerPage: 30,
          }}
          dataFetchCallback={(params) => {
            history.push(
              `${location.pathname}?${queryStringSerialize(
                pickBy(params.filter)
              )}`
            );
            return fetch(
              `${
                process.env.REACT_APP_VTS_API
              }/v1/item/receipt.json?${queryStringSerialize(params)}`,
              {
                headers: authHeaders(),
              }
            )
              .then((r) => {
                setLoading(false);
                return r.json();
              })
              .catch((e) => setLoading(false));
          }}
          initialColumns={[
            {
              name: "Item Number",
              key: "item_number",
              filterOptionsCallback: searchNewSelect("item_number"),
              format: function Highlight(v) {
                return <ItemNumberHighLight>{v}</ItemNumberHighLight>;
              },
              cellStyle: (_v, r) => (r.ncr ? { color: "red" } : {}),
            },
            {
              name: "Description",
              key: "description",
              filterType: "text",
            },
            {
              name: "UOM",
              key: "uom",
              filterOptionsCallback: searchNewSelect("uom"),
            },
            {
              name: "Customer Item",
              key: "customer_item",
              filterOptionsCallback: searchNewSelect("customer_item"),
            },
            {
              name: "Customer Drawing",
              key: "customer_drawing",
              filterOptionsCallback: searchNewSelect("customer_drawing"),
            },
            {
              name: "Date",
              key: "date",
              format: (v) => new Date(v).toLocaleDateString("no"),
              filterType: "date",
              sort: "asc",
            },
            {
              name: "Purchase Order",
              key: "purchase_order_number",
              cellStyle: { whiteSpace: "pre-line" },
              disableSorting: true,
              disableFilter: true,
              format: (v) =>
                v.map((p, i) => (
                  <React.Fragment key={i}>
                    <PurchaseHighLight>{p}</PurchaseHighLight>
                    <br />
                  </React.Fragment>
                )),
            },
            {
              name: "Purch.",
              key: "purchaser_code",
              cellStyle: { whiteSpace: "pre-line" },
              disableSorting: true,
              disableFilter: true,
              format: (v) => v.join("\n"),
            },
            {
              name: "In Control",
              key: "total",
              filterType: "number",
            },
          ]}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

ReceiptControl.defaultProps = {
  filters: {},
};

export default ReceiptControl;
