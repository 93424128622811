import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import debounce from "debounce-promise";
import searchNewSelect from "../../../lib/searchNewSelect";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { onUpdateProjectFilter } from "../../../redux/features/projects/components";

const ProjectFilter = () => {
  //Redux hooks
  const dispatch = useAppDispatch();
  //redux state
  const projectFilter = useAppSelector(
    (state) => state["project/components"].projectFilter
  );
  //local states
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  //debounce hook
  const applySearch = debounce((value) => setSearchTerm(value), 250);

  useEffect(() => {
    const searchFunction = searchNewSelect("project_number");
    setLoading(() => true);
    setOptions([]);
    searchFunction(searchTerm).then((r) => {
      setOptions(r.results);
      setLoading(false);
    });
  }, [searchTerm]);

  const handleFilter = (_e, value) => {
    applySearch(value);
  };

  const handleChange = (_e, data) => {
    dispatch(onUpdateProjectFilter(data));
  };

  return (
    <Autocomplete
      onInputChange={handleFilter}
      onChange={handleChange}
      value={projectFilter}
      id="components-filter"
      loading={loading}
      //Remove local filter functionality
      filterOptions={(opts) => opts}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      getOptionLabel={(option) => option.text}
      // fullWidth
      renderInput={(params) => (
        <TextField {...params} label="Project" variant="outlined" />
      )}
    />
  );
};

export default ProjectFilter;
