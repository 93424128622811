import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Drawer,
  TextField,
  Stack,
} from "@mui/material";
import loadFilterOptions from "../../../../services/production/queue-list/loadFilterOptions";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import SelectAutoComplete from "./SelectAutoComplete";
import validate from "./validate";
import moment from "moment";
import { useDateMask } from "../../../../hooks/useLocaleMask";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const DEFAULT_PROD_ORDER_STATUS = ["Released"];

const DEFAULT_STATE = {
  start_date: null,
  end_date: null,
  address: null,
  machine_number: null,
  name: null,
  work: null,
  prod_order_status: DEFAULT_PROD_ORDER_STATUS,
};

const Filters = ({
  selectedFilters,
  open,
  onSubmit,
  onClose,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mask } = useDateMask();

  // Values
  const [values, setValues] = useState({
    start_date: selectedFilters?.start_date ?? startDate ?? null,
    end_date: selectedFilters?.end_date ?? endDate ?? null,
    address: selectedFilters?.address || null,
    machine_number: selectedFilters?.machine_number || null,
    name: selectedFilters?.name || null,
    work: selectedFilters?.work || null,
    prod_order_status:
      selectedFilters?.prod_order_status || DEFAULT_PROD_ORDER_STATUS,
  });

  const anyRequiredFieldSelected = ["address", "machine_number", "name", "work"]
    .map((e) => values[e])
    .some(Boolean);

  // Filter options
  const [filterOptions, setFilterOptions] = useState({});
  // Loading filter options feedback
  const [loadingFilters, setLoadingFilters] = useState(true);
  // Validation error
  const [error, setError] = useState({});

  const handleChange = (key, value) => {
    setValues((oldValues) => ({ ...oldValues, [key]: value }));
  };

  const handleSubmit = () => {
    const validationValues = validate(values, t);
    if (validationValues) {
      setError(validationValues);
      return;
    }
    setError({});
    onSubmit(values);
  };

  useEffect(() => {
    // Load filters options
    setLoadingFilters(true);
    loadFilterOptions()
      .then((filterOptions) => {
        setFilterOptions(filterOptions);
      })
      .catch((e) => {
        enqueueSnackbar(t("Failed to load filters"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoadingFilters(false);
      });
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    // Automatically updates the date range picker based on backend response
    if (startDate && endDate) {
      setValues((prev) => ({
        ...prev,
        start_date: new Date(startDate).toISOString(),
        end_date: new Date(endDate).toISOString(),
      }));
    }
  }, [startDate, endDate]);

  const startDateText = values?.start_date
    ? t("Start date")
    : `${t("Start date")} (${t("automatic")})`;
  const endDateText = values?.end_date
    ? t("End date")
    : `${t("End date")} (${t("automatic")})`;

  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <Box width={340}>
        <Box display="flex" alignItems="center" m={2}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {t("Filters")}
          </Typography>
          <Button
            disabled={loadingFilters}
            onClick={() => setValues(DEFAULT_STATE)}
            color="inherit"
          >
            {t("Reset")}
          </Button>
          <Button
            disabled={loadingFilters}
            onClick={handleSubmit}
            color="primary"
          >
            {t("Save")}
          </Button>
        </Box>
        <Box m={2} display="flex" flexDirection="column">
          <Stack spacing={2}>
            <DateRangePicker
              calendars={1}
              mask={mask}
              startText={startDateText}
              endText={endDateText}
              clearable
              value={[
                values.start_date && moment(values.start_date),
                values.end_date && moment(values.end_date),
              ]}
              onChange={(values) =>
                setValues((oldValues) => ({
                  ...oldValues,
                  start_date: values[0]?.startOf("day").toISOString() || null,
                  end_date: values[1]?.endOf("day").toISOString() || null,
                }))
              }
              renderInput={(startProps, endProps) => (
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <TextField {...startProps} fullWidth size="small" />

                  <TextField {...endProps} fullWidth size="small" />
                </Stack>
              )}
            />
            <SelectAutoComplete
              label={t("Address")}
              id="address"
              onChange={(value) => handleChange("address", value)}
              options={filterOptions.addresses || []}
              value={values.address}
              inputProps={{
                error: !!error.address,
                helperText: error.address,
                required: !anyRequiredFieldSelected,
              }}
              disabled={loadingFilters}
              size="small"
            />
            <SelectAutoComplete
              label={t("Manager")}
              id="name"
              onChange={(value) => handleChange("name", value)}
              options={filterOptions.manager_names || []}
              value={values.name}
              inputProps={{
                error: !!error.name,
                helperText: error.name,
                required: !anyRequiredFieldSelected,
              }}
              disabled={loadingFilters}
              size="small"
            />
            <SelectAutoComplete
              label={t("Work center number")}
              id="work"
              onChange={(value) => handleChange("work", value)}
              options={filterOptions.work_center_numbers || []}
              value={values.work}
              inputProps={{
                error: !!error.work,
                helperText: error.work,
                required: !anyRequiredFieldSelected,
              }}
              disabled={loadingFilters}
              size="small"
            />
            <SelectAutoComplete
              label={t("Machine number")}
              id="machine_number"
              onChange={(value) => handleChange("machine_number", value)}
              options={filterOptions.machine_numbers || []}
              value={values.machine_number}
              inputProps={{
                error: !!error.machine_number,
                helperText: error.machine_number,
                required: !anyRequiredFieldSelected,
              }}
              disabled={loadingFilters}
              size="small"
            />

            <SelectAutoComplete
              label={t("Production order status")}
              id="prod_order_status"
              onChange={(value) => {
                handleChange("prod_order_status", value);
              }}
              options={filterOptions.prod_order_status || []}
              value={values.prod_order_status}
              disabled={loadingFilters}
              size="small"
              multiple
            />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Filters;
