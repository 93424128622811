import { useState, useEffect, useMemo } from "react";

import { alpha, Fade, IconButton, Link } from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import FilterIcon from "@mui/icons-material/FilterList";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import { RemoteDataTable } from "@oddadigitalsystem/datatable";
import { parse } from "query-string";

import { pickBy } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import AppBarButtonWrapper from "../../../components/AppBarButtonWrapper";
import ProjectHighLight from "../../../components/HighLight/ProjectHighLight";
import ProductionHighLight from "../../../components/HighLight/ProductionHighLight";

import Filters from "./filters/Filters";
import LoadingColumns from "./LoadingColumns";
import HideIfNoFilters from "./HideIfNoFilters";
import RoutingStatusCell from "./RoutingStatusCell";
import { getFiltersFromQueryString } from "./queryStringFilterOperations";

import loadQueueListData from "../../../services/production/queue-list/loadQueueListData";
import loadWeekColumns from "../../../services/production/queue-list/loadWeekColumns";
import { getISOWeek, parseISO, format, isThisWeek } from "date-fns";
import FullpageTableScrollContainer from "../../../components/FullpageTableScrollContainer";
import NcrCell from "../../../components/NcrCell";

const generateWeekHeader = (isostring, total) => {
  const parsedDate = parseISO(isostring);
  const weekNumber = getISOWeek(parsedDate);
  const totalDisplay = total ? `${total}` : "";
  const date = format(parsedDate, "dd.MM.yy");

  return `${totalDisplay} 
  W: ${weekNumber} 
  ${date}`;
};

const wrapHeader = {
  whiteSpace: "pre-line",
  overflowWrap: "break-word",
  lineHeight: 1.2,
  fontSize: 12,
  padding: 6,
};

const cellStyle = { fontSize: 12, padding: 6 };

const OpenInNewWindow = ({ value, href, ...props }) => (
  <Link
    href={`${href}`}
    target="_blank"
    rel="noreferrer noopener"
    color="#000"
    underline="always"
  >
    {value}
  </Link>
);

const QueueList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const params = useMemo(
    () => parse(location.search, { arrayFormat: "bracket" }),
    [location.search]
  );

  // Feedback for the appbar
  const [loading, setLoading] = useState(false);
  // Trigger the table re-render
  const [lastOutsideRefreshTrigger, setLastOutsideRefreshTrigger] = useState();

  // Filters that belong to outside the table
  const [filters, setFilters] = useState(getFiltersFromQueryString(params));
  const missingFilters = Object.keys(filters).length === 0;

  // Show/hide filters sidebar
  const [openFilterDrawer, setOpenFilterDrawer] = useState(
    missingFilters ?? false
  );

  // Columns for the weeks in the table
  const [weekColumns, setWeekColumns] = useState({});
  // Loading columns feedback
  const [loadingColumns, setLoadingColumns] = useState(false);

  const handleUpdateFilters = (newFilters) => {
    // Update the filters removing useless objects
    setFilters(pickBy(newFilters));
    // close sidebar
    setOpenFilterDrawer(false);

    const queryString = queryStringSerialize({ filter: pickBy(newFilters) });
    history.replace({ search: queryString });

    // Reload the week days columns
    setLoadingColumns(true);
    loadWeekColumns({ filter: pickBy(newFilters) })
      .then((columns) => {
        setWeekColumns(columns);
      })
      .catch((error) => {
        enqueueSnackbar(`${t("Failed to load the table columns")}: ${error}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoadingColumns(false);
        setLastOutsideRefreshTrigger(new Date().getTime());
      });
  };

  useEffect(() => {
    // Load initial week days columns
    if (!missingFilters) {
      setLoadingColumns(true);
      loadWeekColumns(params)
        .then((r) => {
          setWeekColumns(r);
        })
        .catch((error) => {
          enqueueSnackbar(
            `${t("Failed to load the table columns")}: ${error}`,
            {
              variant: "error",
            }
          );
        })
        .finally(() => {
          setLoadingColumns(false);
        });
    }
    // This should run only on the first render, that's why params should not be here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, t, missingFilters]);

  // Default load data function
  const handleLoadData = ({ search, ...parameters }) => {
    const mergedParams = {
      ...parameters,
      filter: {
        ...parameters?.filter,
        ...filters,
      },
      sort: parameters.sort || {},
    };
    if (search) mergedParams.filter.search = search;
    const queryString = queryStringSerialize(mergedParams);
    history.replace({ search: queryString });

    setLoading(true);
    return loadQueueListData(queryString)
      .catch((e) => {
        enqueueSnackbar(
          `${t("Failed to load production queue list data")}: ${e?.toString()}`,
          { variant: "error" }
        );
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableRefreshDependency = `${lastOutsideRefreshTrigger}`;
  const hasWeeks = Object.keys(weekColumns).length > 0;

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading || loadingColumns}>
        <>
          <IconButton
            onClick={() => setOpenFilterDrawer((oldState) => !oldState)}
            disabled={loading}
            size="large"
          >
            <FilterIcon />
          </IconButton>
          <IconButton
            onClick={() => setLastOutsideRefreshTrigger(new Date().getTime())}
            disabled={loading}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </>
      </AppBarButtonWrapper>
      <Filters
        selectedFilters={filters}
        open={openFilterDrawer}
        onSubmit={handleUpdateFilters}
        onClose={() => setOpenFilterDrawer(false)}
        startDate={weekColumns?.start_date ?? null}
        endDate={weekColumns?.end_date ?? null}
      />
      <FullpageTableScrollContainer>
        {loadingColumns && <LoadingColumns />}
        <HideIfNoFilters filters={getFiltersFromQueryString(params)}>
          <Fade in={!loadingColumns} timeout={200}>
            <div>
              {hasWeeks && (
                <RemoteDataTable
                  key={tableRefreshDependency}
                  size="small"
                  dataFetchCallback={handleLoadData}
                  enableSearch
                  initialColumns={[
                    {
                      disableSorting: true,
                      key: "work_center_number",
                      name: t("Work Center"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "number",
                      name: t("Machine Number"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "description",
                      name: t("Description"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "job_number",
                      name: t("Project Number"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: (v) => <ProjectHighLight>{v}</ProjectHighLight>,
                    },
                    {
                      disableSorting: true,
                      key: "project_name",
                      name: t("Project Name"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "production_order",
                      name: t("Production Order"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: (v, { ncr }) => (
                        <NcrCell ncr={ncr}>
                          <ProductionHighLight
                            LinkProps={{ color: "inherit", underline: "none" }}
                          >
                            {v}
                          </ProductionHighLight>
                        </NcrCell>
                      ),
                    },
                    {
                      key: "ncr",
                      hide: true,
                    },
                    {
                      disableSorting: true,
                      key: "days",
                      name: t("Days"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "item_description",
                      name: t("Item Description"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: ({ label, link_reference }) => (
                        <OpenInNewWindow
                          href={`/items/lessons-learned?customer_drawing=${link_reference.drawing_no}`}
                          value={label}
                        />
                      ),
                    },
                    {
                      disableSorting: true,
                      key: "drawing_item_number",
                      name: t("Drawing Number"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: ({ label, link_reference }) => (
                        <OpenInNewWindow
                          href={`/documents/documents?item_number=${link_reference.item_no}`}
                          value={label}
                        />
                      ),
                    },
                    {
                      disableSorting: true,
                      key: "operation",
                      name: t("Operation"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: ({ label, link_reference }) => (
                        <OpenInNewWindow
                          href={`/production/operations?operation=${link_reference.operation_no}&prod_order=${link_reference.prod_order_no}`}
                          value={label}
                        />
                      ),
                    },
                    {
                      disableSorting: true,
                      key: "status",
                      name: t("Status"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    ...Object.entries(weekColumns.total_weeks).map(
                      ([key, { date, total }]) => {
                        const isCurrentWeek = isThisWeek(parseISO(date));
                        const highlightColor = "rgba(255, 210, 0)";

                        const highlightBackgroundColor = isCurrentWeek
                          ? alpha(highlightColor, 0.2)
                          : undefined;

                        return {
                          disableSorting: true,
                          key,
                          name: generateWeekHeader(date, total),
                          columnStyle: {
                            ...wrapHeader,
                            fontSize: 12,
                          },
                          cellStyle: {
                            // make the content fit the cell
                            height: "1em",
                            padding: "0px",
                            borderBottom: "none",
                            borderLeft: `1px solid rgba(224, 224, 224, 1)`,
                            backgroundColor: highlightBackgroundColor,
                          },

                          format: (v) => <RoutingStatusCell {...v} />,
                        };
                      }
                    ),
                  ]}
                />
              )}
            </div>
          </Fade>
        </HideIfNoFilters>
      </FullpageTableScrollContainer>
    </>
  );
};

export default QueueList;
