import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ListIcon from "@mui/icons-material/List";
import TimelineIcon from "@mui/icons-material/Timeline";
import CachedIcon from "@mui/icons-material/Cached";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import ViewListIcon from "@mui/icons-material/ViewList";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import StorageIcon from "@mui/icons-material/Storage";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";

import {
  EmployeesPerDayIcon,
  TeamsIcon,
  NcrIcon,
  ProgressPerResourceIcon,
  ConfigureReportsIcon,
  QueueListIcon,
} from "./icons";
import HomeButtons from "../pages/home/HomeButtons";
import BackButton from "../pages/purchase/create-receival-orders/BackButton";

//documents
import Mrb from "../pages/documents/Mrb";
import FMCImports from "../pages/documents/fmc-import/FMCImport";

//purchase
import ItemsToBuy from "../pages/purchase/items-to-buy/ItemsToBuy";
import ItemsToReceive from "../pages/purchase/items-to-receive/ItemsToReceive";
import ProductionTimeline from "../pages/production/timeline/ProductionTimeline";
import WorkCenterLoad from "../pages/production/work-center-load/WorkCenterLoad";
import CreateReceivalOrders from "../pages/purchase/create-receival-orders/CreateReceivalOrders";
import WarehouseReceival from "../pages/purchase/warehouse-receival/WarehouseReceival";

//reports
import WeldMaps from "../pages/reports/weld-maps/WeldMaps";
import WeldLog from "../pages/reports/weld-log/WeldLog";

// items
import ReceiptControl from "../pages/items/receipt-control/ReceiptControl";
import PrintableReceiptLabel from "../pages/purchase/create-receival-orders/PrintableReceiptLabel";

//config
import EmployeesPerDay from "../pages/config/EmployeesPerDay";

//project
import OnTimeDelivery from "../pages/project/on-time-delivery/OnTimeDelivery";
import Comments from "../pages/project/comments/Comments";

//Components
import Components from "../pages/project/components/Components";
import ComponentsButtons from "../pages/project/components/ComponentsButtons";

//hseq
import Ncr from "../pages/hseq/ncr/Ncr";
import AddNcrFromIframe from "../pages/hseq/ncr/AddNcrFromIframe";

//production
import ProgressPerResource from "../pages/production/progress-per-resource/ProgressPerResource";
import QueueList from "../pages/production/queue-list/QueueList";
import WorkCenters from "../pages/production/queue-list/work-centers/WorkCenters";

const routes = () => {
  const { railsUserId } = JSON.parse(localStorage.getItem("msToken"));
  return [
    { category: "Dashboards" },
    {
      title: "Home",
      path: "/home",
      icon: HomeOutlinedIcon,
      buttons: HomeButtons,
      component: process.env.REACT_APP_VTS_ENDPOINT,
    },
    { category: "HSEQ" },
    {
      title: "NCR",
      icon: NcrIcon,
      path: "/ncr",
      component: Ncr,
      fullPage: true,
    },
    {
      title: "NCR Overview",
      icon: EqualizerIcon,
      path: "/ncr/overview",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_overview",
    },
    {
      title: "NCR Details",
      icon: ChromeReaderModeOutlinedIcon,
      path: "/ncr/details",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_details",
    },
    {
      title: "NCR Age Destribution",
      icon: PieChartOutlinedIcon,
      path: "/ncr/age-distrbution",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_age_distribution",
    },
    {
      title: "NCR Categories",
      icon: CategoryOutlinedIcon,
      path: "/ncr-categories",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_categories",
    },
    {
      title: "NCR Progress",
      icon: ShowChartIcon,
      path: "/ncr/progress",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_progress",
    },
    {
      title: "NCR List",
      icon: ListIcon,
      path: "/ncr/list",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_list",
      buttons: AddNcrFromIframe,
    },
    {
      title: "NCR Root Cause",
      icon: TimelineIcon,
      path: "/ncr/root-cause",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/ncr_root_cause",
    },
    {
      title: "Print NCR",
      icon: PrintOutlinedIcon,
      path: "/ncr/print-ncr",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/hseq/print_ncr",
    },
    { category: "Production" },
    {
      title: "Queue List",
      subtitle: "Work Centers",
      icon: QueueListIcon,
      path: "/production/queue-list/work-centers",
      component: WorkCenters,
    },
    {
      title: "Queue List",
      icon: TimelineIcon,
      path: "/production/queue-list",
      component: QueueList,
      fullPage: true,
      hidden: true,
    },
    {
      title: "Production Queue List",
      icon: QueueOutlinedIcon,
      path: "/production/work-centers",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/production/work_centers",
    },
    {
      title: "In Progress",
      icon: CachedIcon,
      path: "/production/in-progress",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/production/in_progress",
    },
    {
      title: "Load and Capacity",
      icon: FitnessCenterIcon,
      path: "/production/load-and-capacity",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/production/load_and_capacity",
    },
    {
      title: "Work Center Load",
      icon: WorkOutlineOutlinedIcon,
      path: "/production/work-center-load",
      component: WorkCenterLoad,
    },
    {
      title: "Production Order",
      icon: ShoppingBasketOutlinedIcon,
      path: "/production/production-order",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/production/orders",
    },
    {
      title: "Operations",
      icon: BuildOutlinedIcon,
      path: "/production/operations",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/production/operations",
    },
    {
      title: "Progress Per Resource",
      icon: FindReplaceIcon,
      path: "/production/progress-per-resource/old",
      component:
        process.env.REACT_APP_VTS_ENDPOINT +
        "/production/progress_per_resource",
    },
    {
      title: "Progress per Resource (Beta)",
      icon: ProgressPerResourceIcon,
      path: "/production/progress-per-resource",
      component: ProgressPerResource,
      fullPage: true,
    },
    {
      title: "Supplier View",
      icon: ViewListIcon,
      path: "/production/supplier-view",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/production/supplier_view",
    },
    {
      title: "Trace List",
      icon: ListIcon,
      path: "/production/trace-list",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/production/trace_list",
    },
    {
      title: "Production Timeline",
      icon: TimelineIcon,
      path: "/production/timeline",
      component: ProductionTimeline,
    },

    { category: "Project" },
    {
      title: "Deliveries",
      icon: LocalShippingOutlinedIcon,
      path: "/project/deliveries",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/deliveries",
    },
    {
      title: "Hours Planned",
      icon: ScheduleIcon,
      path: "/project/hours-planned",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/hours_planned",
    },
    {
      title: "Old Comments",
      path: "/project/old-comments",
      icon: CommentOutlinedIcon,
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/comments",
    },
    {
      title: "Comments",
      icon: CommentOutlinedIcon,
      path: "/project/comments",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/comments",
    },
    {
      title: "Comments (Beta)",
      icon: CommentOutlinedIcon,
      path: "/project/comments-beta",
      component: Comments,
      fullPage: true,
      hidden: true,
    },
    {
      title: "Add Comment",
      icon: CommentOutlinedIcon,
      path: "/project/comments/new",
      hidden: true,
      component: process.env.REACT_APP_VTS_ENDPOINT + "/comments/new",
    },
    {
      title: "Tasks",
      icon: AssignmentOutlinedIcon,
      path: "/project/tasks",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/tasks",
    },
    {
      title: "Components",
      buttons: ComponentsButtons,
      icon: ViewModuleOutlinedIcon,
      path: "/project/components",
      component: Components,
      fullPage: true,
    },
    {
      title: "Customer View",
      icon: SupervisedUserCircleOutlinedIcon,
      path: "/project/customer-view",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/customer_view",
    },
    {
      title: "Finance",
      icon: AttachMoneyIcon,
      path: "/project/finance",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/finance",
    },
    {
      title: "On Time Delivery",
      icon: HourglassEmptyIcon,
      path: "/project/on-time-delivery",
      component: OnTimeDelivery,
    },
    {
      title: "Components Timeline",
      icon: TimelineIcon,
      path: "/project/components-timeline",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/project/components/index_old",
    },
    {
      title: "Planning",
      icon: ScheduleIcon,
      path: "/project/planning",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/project/planning/gantt",
    },

    { category: "Items" },
    {
      title: "Inventory",
      icon: StorageIcon,
      path: "/items/inventory",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/item/inventory",
    },
    {
      title: "Customer Inventory",
      icon: StoreOutlinedIcon,
      path: "/items/customer-inventory",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/item/inventory/customer",
    },
    {
      title: "BOM",
      icon: ReceiptOutlinedIcon,
      path: "/items/bom",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/item/bom",
    },
    {
      title: "Programs and Tools",
      icon: BuildOutlinedIcon,
      path: "/items/tools",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/item/programs_and_tools",
    },
    {
      title: "Lessons Learned",
      icon: MenuBookIcon,
      path: "/items/lessons-learned",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/item/lessons_learned",
    },
    {
      title: "Routing",
      icon: FiberManualRecordOutlinedIcon,
      path: "/items/routing",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/item/routing",
    },
    {
      title: "Receipt Control",
      icon: ListAltIcon,
      path: "/items/receipt-control",
      component: ReceiptControl,
      fullPage: true,
    },

    { category: "Documents" },
    {
      title: "Add Document",
      icon: NoteAddOutlinedIcon,
      path: "/documents/documents-new",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/documents/documents/new",
    },
    {
      title: "Documents",
      icon: DescriptionOutlinedIcon,
      path: "/documents/documents",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/documents/documents",
    },
    {
      title: "SMDR",
      icon: DescriptionOutlinedIcon,
      path: "/documents/smdr",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/documents/smdr",
    },
    {
      title: "MRB",
      icon: DescriptionOutlinedIcon,
      path: "/documents/mrb",
      component: Mrb,
    },
    {
      hidden: true,
      title: "Add MRB",
      path: "/documents/mrbs/new",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/documents/mrbs/new",
    },
    {
      title: "ECN",
      icon: DescriptionOutlinedIcon,
      path: "/documents/ecn",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/documents/ecn",
    },
    {
      title: "ITP",
      icon: DescriptionOutlinedIcon,
      path: "/documents/itp",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/documents/itp",
    },
    {
      title: "Check Active",
      icon: DescriptionOutlinedIcon,
      path: "/documents/check-active",
      component:
        process.env.REACT_APP_VTS_ENDPOINT + "/documents/actions/check_active",
    },
    {
      title: "FMC Import",
      icon: UploadFileIcon,
      path: "/documents/fmc-import",
      component: FMCImports,
    },

    { category: "Reports" },
    {
      title: "Weld log",
      icon: DescriptionOutlinedIcon,
      path: "/reports/weld-log/:id",
      component: WeldLog,
      hidden: true,
      fullPage: true,
    },
    {
      title: "Reports",
      icon: DescriptionOutlinedIcon,
      path: "/reports/reports",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/reports/reports",
    },
    {
      title: "Old QC Reports",
      icon: DescriptionOutlinedIcon,
      path: "/reports/qc-report",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/qc_reports",
    },
    {
      title: "Weld Maps",
      icon: DescriptionOutlinedIcon,
      path: "/reports/weld-maps",
      component: WeldMaps,
      fullPage: true,
    },
    {
      title: "Create Weld Map",
      icon: DescriptionOutlinedIcon,
      path: "/reports/weld-maps/new",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/weld_maps/new",
      hidden: true,
      fullPage: true,
    },
    { category: "Purchase" },
    {
      title: "Purchase Orders",
      icon: ShoppingBasketOutlinedIcon,
      path: "/purchase/orders",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/purchase/orders",
    },
    {
      title: "Receipts",
      icon: ReceiptOutlinedIcon,
      path: "/purchase/receipts",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/purchase/receipts",
    },
    {
      title: "Items to buy",
      icon: ShoppingCartOutlinedIcon,
      path: "/purchase/items-to-buy",
      component: ItemsToBuy,
      fullPage: true,
    },
    {
      title: "Items to receive",
      icon: AddShoppingCartIcon,
      path: "/purchase/items-to-receive",
      component: ItemsToReceive,
      fullPage: true,
    },
    {
      title: "Warehouse receival",
      icon: WarehouseOutlinedIcon,
      path: "/purchase/warehouse-receival",
      component: WarehouseReceival,
      fullPage: true,
    },
    {
      title: "Create receival orders",
      icon: PostAddOutlinedIcon,
      path: "/purchase/create-receival-orders",
      component: CreateReceivalOrders,
      fullPage: true,
    },
    { category: "Config" },
    {
      title: "Users",
      icon: GroupOutlinedIcon,
      path: "/users",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/users",
    },
    {
      title: "Employees Per Day",
      icon: EmployeesPerDayIcon,
      path: "/config/employees-per-day",
      component: EmployeesPerDay,
    },
    {
      title: "Configure Reports",
      icon: ConfigureReportsIcon,
      path: "/config/reports",
      component: process.env.REACT_APP_VTS_ENDPOINT + "/reports/configurations",
    },
    { category: "User" },
    {
      title: "Profile",
      icon: AccountBoxOutlinedIcon,
      path: `/profile/${railsUserId}`,
      component: `${process.env.REACT_APP_VTS_ENDPOINT}/users/${railsUserId}`,
    },
    { category: "Support" },
    {
      title: "Ask in Teams",
      icon: TeamsIcon,
      onClick: () =>
        window.open(
          "https://teams.microsoft.com/l/chat/0/0?users=asmundbu@oddadigitalsystem.no"
        ),
    },
    {
      title: "Ask by email",
      icon: EmailOutlinedIcon,
      onClick: () => window.open("mailto:asmundbu@oddadigitalsystem.no"),
    },
    {
      hidden: true,
      title: "Print label",
      buttons: BackButton,
      path: "/purchase/create-receival-orders/print-label",
      component: PrintableReceiptLabel,
    },
  ];
};
export default routes;
