import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingRight: "39px",
            // Fix Loading indicator in Autocomplete when using small input
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#19b24b",
    },
    secondary: {
      main: "#19b24b",
    },
    contrastThreshold: 2,
  },
});

export const darkTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingRight: "39px",
            // Fix Loading indicator in Autocomplete when using small input
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#fdfdfd",
    },
    secondary: {
      main: "#19b24b",
    },
  },
});
