import { LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import statusColors from "./statusColors.json";

const useProgressStyle = makeStyles(() => ({
  done: {
    backgroundColor: statusColors["done"],
  },
  inProgress: {
    backgroundColor: statusColors["in_progress"],
  },
  colorPrimary: {
    backgroundColor: "lightgray",
  },
}));

const ProgressCell = ({ routing }) => {
  const classes = useProgressStyle();
  const completed = routing.filter(({ status }) => status === "Done")?.length;
  const total = routing?.length;
  const isDone = completed === total;
  const progress = (completed * 100) / total;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          classes={{
            colorPrimary: classes.colorPrimary,
            bar: isDone ? classes.done : classes.inProgress,
          }}
        />
      </div>
      <div style={{ marginTop: "5px" }}>
        <Typography variant="body2" color="textSecondary">
          {completed + " / " + total}
        </Typography>
      </div>
    </div>
  );
};
export default ProgressCell;
