/* eslint-disable @typescript-eslint/no-unused-vars */
import authHeaders from "../../../lib/authHeaders";
import fetchUrl from "../../../lib/fetchUrl";
import { ProjectComponentsApiResponse } from "../../../types";

const getData = async (
  project?: string,
  params?: any | null | undefined
): Promise<ProjectComponentsApiResponse> => {
  const { sort, filter, search, page, limit } = params || {};
  project = project || "";
  return fetch(
    fetchUrl(
      "/v2/project/components",
      { project, limit: 99999, page: 1 },
      process.env.REACT_APP_VTS_API
    ),
    { headers: authHeaders() }
  ).then((r) => r.json());
};

export { getData };
