import {
  Box,
  Paper,
  Container,
  IconButton,
  Grow,
  Slide,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Redirect, useParams } from "react-router";
import { useSnackbar } from "notistack";

import PrintIcon from "@mui/icons-material/Print";

import { WeldReport } from "./components/WeldReport";
import AppBarButtonWrapper from "../../../components/AppBarButtonWrapper";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { onChangeTitle } from "../../../redux/features/app-common-slice";

// Requests
import getWeldLogInfo from "../../../services/weldLog/getWeldLogInfo";
import approveWeldLog from "../../../services/weldLog/approveWeldLog";
import disapproveWeldLog from "../../../services/weldLog/disapproveWeldLog";

const WeldLog = () => {
  const { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  const dispatch = useDispatch();
  const printRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const title = `Weld Log Report: ${id}`;
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    dispatch(onChangeTitle("Weld Log Report"));
    document.title = "Weld Log Report - Visual Tracking";
  }, [dispatch]);

  const handleSign = (approve) => {
    setLoading(true);
    const handleUpdateSign = approve ? approveWeldLog : disapproveWeldLog;

    handleUpdateSign(id)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError("Failed to update signature");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (id)
      getWeldLogInfo(id)
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((e) => {
          if (e.status === 404) setError("Not found");
          else {
            enqueueSnackbar(
              "Failed to load the data, please try again later or contact the support.",
              { variant: "error" }
            );
            setError(e.statusText);
          }
          setLoading(false);
        });
    else {
      setLoading(false);
      setError("Not found");
    }
  }, [id, enqueueSnackbar]);

  if (!loading && !data && error === "Not found")
    return <Redirect to="/not-found" />;
  else
    return (
      <>
        <AppBarButtonWrapper showSpinner={loading}>
          <Grow in={!loading} timeout={500}>
            <IconButton onClick={handlePrint} disabled={loading} size="large">
              <PrintIcon />
            </IconButton>
          </Grow>
        </AppBarButtonWrapper>
        <Box
          pt={isMobile ? 0 : 3}
          height={1200}
          style={{ backgroundColor: "white" }}
        >
          <Slide in direction="up" timeout={500}>
            <Container maxWidth="md" disableGutters={isMobile}>
              <Box
                p={3}
                component={Paper}
                elevation={isMobile ? 0 : 5}
                variant="outlined"
              >
                <WeldReport
                  data={data}
                  title={title}
                  loading={loading}
                  printRef={printRef}
                  onSign={handleSign}
                  error={error}
                />
              </Box>
            </Container>
          </Slide>
        </Box>
      </>
    );
};

export default WeldLog;
