import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

interface ConfirmDialogProps {
  open: boolean;
  onClose?: () => void;
  onConfirm: (retry:boolean) => void;
  loading?: boolean;
  error?: boolean;
}

const ConfirmDialogTracking: FunctionComponent<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  loading,
  error,
}) => {
  return (
    <Dialog {...{ open, onClose }}>
      <DialogTitle>Fill tracking numbers?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please verify the data in the table before proceeding. This process
          can take up to several minutes
        </DialogContentText>
        {error && (
          <>
            <DialogContentText color="error">
              There are previous lines that failed to create .
            </DialogContentText>
            <Button
              onClick={() => onConfirm(true)}
              variant="outlined"
              startIcon={<ReplayIcon />}
            >
              Retry failed tracking lines
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose && onClose()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(false)}
          color="primary"
          autoFocus
          disabled={loading}
        >
          Add tracking information
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialogTracking.propTypes = {
  error: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default ConfirmDialogTracking;
