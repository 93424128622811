import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { onUpdateProjectFilter } from "../../../redux/features/projects/components";
import { IconButton, SvgIcon } from "@mui/material";
import { mdiFilterVariantRemove } from "@mdi/js";

const ComponentsButtons = () => {
  const dispatch = useAppDispatch();
  const isProjectSelected = useAppSelector((state) =>
    Boolean(state["project/components"].projectFilter)
  );

  const handleCLearFilter = () => {
    dispatch(onUpdateProjectFilter(null));
  };

  return isProjectSelected ? (
    <IconButton
      onClick={handleCLearFilter}
      disabled={!isProjectSelected}
      size="large"
    >
      <SvgIcon>
        <path d={mdiFilterVariantRemove} />
      </SvgIcon>
    </IconButton>
  ) : null;
};

export default ComponentsButtons;
