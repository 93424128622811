import React, { useState } from "react";
import { RemoteDataTable } from "@oddadigitalsystem/datatable";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import authHeaders from "../../../lib/authHeaders";
import ProjectHighLight from "../../../components/HighLight/ProjectHighLight";
import PurchaseHighLight from "../../../components/HighLight/PurchaseHighLight";
import ItemNumberHighLight from "../../../components/HighLight/ItemNumberHighLight";
import ItemsToReceiveButtons from "./ItemsToReceiveButtons";
import { useHistory, useLocation } from "react-router";
import { pickBy } from "lodash";
import queryString from "query-string";
import prepareFilter from "../../../lib/prepareFilter";
import searchNewSelect from "../../../lib/searchNewSelect";
import FullpageTableScrollContainer from "../../../components/FullpageTableScrollContainer";

const ItemsToReceive = () => {
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let location = useLocation();
  const initialFilters = prepareFilter(queryString.parse(location.search));

  return (
    <>
      <ItemsToReceiveButtons
        loading={loading}
        filters={queryString.parse(location.search)}
      />
      <FullpageTableScrollContainer>
        <RemoteDataTable
          initialFilters={initialFilters}
          size="small"
          dataFetchCallback={(params) => {
            history.push(
              `${location.pathname}?${queryStringSerialize(
                pickBy(params.filter)
              )}`
            );
            return fetch(
              `${
                process.env.REACT_APP_VTS_API
              }/v1/purchase/items_to_receive?${queryStringSerialize(params)}
              `,
              {
                headers: authHeaders(),
              }
            )
              .then((r) => {
                setLoading(false);
                return r.json();
              })
              .catch((e) => setLoading(false));
          }}
          enableFiltering
          enableSorting
          requestOptions={{
            rowsPerPage: 30,
          }}
          initialColumns={[
            {
              name: "Project",
              key: "job_no",
              format: function Highlight(v) {
                return <ProjectHighLight>{v}</ProjectHighLight>;
              },
              filterOptionsCallback: searchNewSelect("project_number"),
            },
            {
              name: "Order",
              key: "document_no",
              format: function Highlight(v) {
                return <PurchaseHighLight>{v}</PurchaseHighLight>;
              },
              filterOptionsCallback: searchNewSelect("purchase_order_number"),
            },
            { name: "Line", key: "line_no", filterType: "text" },
            {
              name: "Item",
              key: "item_no",
              format: function Highlight(v) {
                return <ItemNumberHighLight>{v}</ItemNumberHighLight>;
              },
              filterOptionsCallback: searchNewSelect("item_number"),
            },
            {
              name: "Cust. Part",
              key: "customer_item",
              filterOptionsCallback: searchNewSelect("customer_item"),
            },
            { name: "Description", key: "description", filterType: "text" },
            {
              name: "Vendor",
              key: "vendor_name",
              filterOptionsCallback: searchNewSelect("vendor_number"),
            },
            { name: "Qty", key: "outstanding_quantity", filterType: "number" },
            {
              name: "Purch",
              key: "purchaser_code",
              filterOptionsCallback: searchNewSelect("purchaser"),
            },
            {
              name: "Requested",
              key: "requested_date",
              cellStyle: (_v, row) =>
                row.requested_receipt_date &&
                new Date(row.requested_receipt_date) < new Date()
                  ? { color: "red" }
                  : {},
              format: (_v, row) =>
                row.requested_receipt_date
                  ? new Date(row.requested_receipt_date).toLocaleDateString(
                      "no"
                    )
                  : null,
              sort: "asc",
              filterType: "date",
            },
            {
              name: "Promised",
              key: "promised_date",
              cellStyle: (_v, row) =>
                row.promised_receipt_date &&
                new Date(row.promised_receipt_date) < new Date()
                  ? { color: "red" }
                  : {},
              format: (_v, row) =>
                row.promised_receipt_date
                  ? new Date(row.promised_receipt_date).toLocaleDateString("no")
                  : null,
              filterType: "date",
            },
          ]}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

ItemsToReceive.defaultProps = {
  filters: {},
};

export default ItemsToReceive;
