import { RemoteDataTable } from "@oddadigitalsystem/datatable";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import moment from "moment";
import ProjectHighLight from "../../../components/HighLight/ProjectHighLight";
import { pickBy } from "lodash";
import searchNewSelect from "../../../lib/searchNewSelect";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Paper, Typography, Box } from "@mui/material";
import {
  ResponsiveContainer,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  Line,
  Bar,
  ComposedChart,
} from "recharts";
import { red, green, blue, yellow } from "@mui/material/colors";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useState } from "react";
import authHeaders from "../../../lib/authHeaders";
import { useHistory, useLocation } from "react-router";
import DownloadFileButton from "../../../components/DownloadFileButton";

const url = `${process.env.REACT_APP_VTS_API}/v1/project/on_time_delivery`;
const graphUrl = `${process.env.REACT_APP_VTS_API}/v1/project/on_time_delivery/kpi_graph`;

const OnTimeDelivery = () => {
  const appBarHeight = document.getElementById("app-bar")?.clientHeight;
  let history = useHistory();
  let location = useLocation();
  const [currentTableParams, setCurrentTableParams] = useState();
  const [graphLoading, setGraphLoading] = useState(false);

  const [graphData, setGraphData] = useState();
  const fetchGraph = () => {
    setGraphLoading(true);
    fetch(graphUrl, {
      headers: authHeaders(),
    })
      .then((r) => r.json())
      .then((r) => {
        setGraphLoading(false);
        setGraphData(r.data);
      })
      .catch((e) => {
        setGraphLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    fetchGraph();
  }, []);

  return (
    <Grid container spacing={2} wrap="wrap-reverse">
      <Grid item xs={12} lg={7}>
        <Paper
          style={{
            overflow: "auto",
            maxHeight: `calc(96vh - ${appBarHeight}px)`,
            height: `calc(96vh - ${appBarHeight}px)`,
          }}
        >
          <RemoteDataTable
            extraToolbarComponent={() => {
              return (
                <Box ml={1}>
                  <DownloadFileButton
                    urlEndpoint={url}
                    filename="On Time Delivery"
                    fileExtension="csv"
                    params={{ ...currentTableParams, limit: 500, page: 1 }}
                    loading={!currentTableParams}
                  />
                </Box>
              );
            }}
            enableSearch
            title="Data"
            size="small"
            requestOptions={{
              rowsPerPage: 30,
            }}
            dataFetchCallback={(params) => {
              setCurrentTableParams(params);
              history.push(
                `${location.pathname}?${queryStringSerialize(
                  pickBy(params.filter)
                )}`
              );
              return fetch(
                `${url}?${queryStringSerialize(params)}`,

                {
                  headers: authHeaders(),
                }
              )
                .then((r) => {
                  return r.json();
                })
                .catch((e) => console.log(e));
            }}
            enableFiltering
            enableSorting
            initialColumns={[
              {
                name: "Project",
                key: "project_number",
                format: (v) => <ProjectHighLight>{v}</ProjectHighLight>,

                filterOptionsCallback: searchNewSelect("project_number"),
              },
              {
                name: "Task",
                key: "task_number",
                filterType: "number",
              },
              {
                name: "Delivery",
                key: "delivery",
                filterType: "text",
              },
              {
                sort: "desc",
                name: "Promised",
                key: "promised_date",
                filterType: "date",
                format: (v) => v && moment(v).format("l"),
              },
              {
                name: "Delivered",
                key: "delivery_date",
                filterType: "date",
                format: (v) => v && moment(v).format("l"),
              },
              { name: "Diff.", key: "difference", filterType: "number" },
              {
                name: "On Time",
                key: "on_time",

                format: (v) =>
                  v ? (
                    <DoneIcon color="primary" />
                  ) : (
                    <ClearIcon color="error" />
                  ),
                filterOptionsCallback: () =>
                  new Promise((resolve) =>
                    resolve({
                      results: [
                        {
                          value: "0",
                          text: "No",
                        },
                        {
                          value: "1",
                          text: "Yes",
                        },
                      ],
                    })
                  ),
              },
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Paper component={Box} p={2} pb={4}>
          <Box ml={1} mb={3} mt={1}>
            <Typography variant="h6">KPI Development</Typography>
          </Box>

          {!graphLoading && graphData?.length > 0 ? (
            <ResponsiveContainer width="100%" height={500}>
              <ComposedChart data={graphData} barSize={50} margin={0}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  unit="%"
                  type="number"
                />
                <XAxis dataKey="date" padding={{ left: 20, right: 20 }} />
                <Tooltip />
                <Bar dataKey="On Time" fill={green["A400"]} stackId="a" />
                <Bar dataKey="Early" fill={blue[500]} stackId="a" />
                <Bar dataKey="Late" fill={red[500]} stackId="a" />
                <Line
                  type="monotone"
                  dataKey="Ratio"
                  stroke={yellow[900]}
                  yAxisId="right"
                />
                <Legend />
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <Box px={4}>
              <Skeleton variant="rectangular" height={460} />
              <Skeleton
                width={250}
                style={{
                  marginTop: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OnTimeDelivery;
