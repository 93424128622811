import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProjectFilter {
  text: string;
  value: string;
}

interface ComponentsState {
  projectFilter: null | ProjectFilter;
}

const initialState: ComponentsState = {
  projectFilter: null,
};

const appCommonSlide = createSlice({
  name: "project/components",
  initialState,
  reducers: {
    onUpdateProjectFilter(state, action: PayloadAction<ProjectFilter | null>) {
      state.projectFilter = action.payload;
    },
  },
});

export const { onUpdateProjectFilter } = appCommonSlide.actions;
export default appCommonSlide.reducer;
