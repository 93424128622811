import { configureStore } from "@reduxjs/toolkit";
import appCommonReducer from "./features/app-common-slice";
import projectComponents from "./features/projects/components";

export const store = configureStore({
  reducer: {
    appCommon: appCommonReducer,
    "project/components": projectComponents,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
