import React, { useState } from "react";
import PropTypes from "prop-types";
import { Fab, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ItemNumberHighLight from "../../../components/HighLight/ItemNumberHighLight";

import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { pickBy } from "lodash";
import { RemoteDataTable } from "@oddadigitalsystem/datatable";
import { queryStringSerialize } from "@oddadigitalsystem/vts-components/utils";
import authHeaders from "../../../lib/authHeaders";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import prepareFilter from "../../../lib/prepareFilter";
import WeldMapsButtons from "./WeldMapsButtons";
import searchNewSelect from "../../../lib/searchNewSelect";
import FullpageTableScrollContainer from "../../../components/FullpageTableScrollContainer";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const WeldMaps = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let location = useLocation();

  const initialFilters = prepareFilter(queryString.parse(location.search));

  return (
    <>
      <WeldMapsButtons
        loading={loading}
        filters={queryString.parse(location.search)}
      />
      <FullpageTableScrollContainer>
        <RemoteDataTable
          initialFilters={initialFilters}
          size="small"
          enableFiltering
          enableSorting
          requestOptions={{
            rowsPerPage: 30,
          }}
          dataFetchCallback={(params) => {
            history.push(
              `${location.pathname}?${queryStringSerialize(
                pickBy(params.filter)
              )}`
            );
            return fetch(
              `${
                process.env.REACT_APP_VTS_API
              }/v1/weld_maps?${queryStringSerialize(params)}
              `,
              {
                headers: authHeaders(),
              }
            )
              .then((r) => {
                setLoading(false);
                return r.json();
              })
              .catch((e) => setLoading(false));
          }}
          initialColumns={[
            {
              name: "Actions",
              key: "id_new",
              format: function Format(_v, row) {
                return (
                  <IconButton
                    key={row.id}
                    onClick={() =>
                      history.push(`/iframe/weld_maps`, {
                        url: `${process.env.REACT_APP_VTS_ENDPOINT}/weld_maps/${row.id}`,
                        title: `Weld Map ${row.id}`,
                      })
                    }
                    size="small"
                  >
                    <VisibilityIcon />
                  </IconButton>
                );
              },
              disableFilter: true,
              disableSorting: true,
            },
            {
              name: "Number",
              key: "id",
              sort: "desc",
              filterType: "text",
            },
            {
              name: "Item",
              key: "item_number",
              format: function Highlight(v) {
                return (
                  <ItemNumberHighLight>{v.toString()}</ItemNumberHighLight>
                );
              },
              filterOptionsCallback: searchNewSelect("item_number"),
            },
            {
              name: "Created At",
              key: "created_at",
              format: (v) =>
                [
                  new Date(v).toLocaleDateString("no"),
                  new Date(v).toLocaleTimeString("no"),
                ].join(" "),
              filterType: "date",
            },
            {
              name: "Creator",
              key: "created_by",
              filterOptionsCallback: searchNewSelect("user"),
            },
            {
              name: "All Welds Approved?",
              key: "all_approved",
              format: (v) => (v ? "Yes" : "No"),
              disableFilter: true,
            },
          ]}
        />
        <Fab
          onClick={() => history.push("/reports/weld-maps/new")}
          color="secondary"
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </FullpageTableScrollContainer>
    </>
  );
};

WeldMaps.propTypes = {
  filters: PropTypes.object,
};

export default WeldMaps;
