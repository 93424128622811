import React from "react";
import DataTable from "@oddadigitalsystem/datatable/DataTable";
import ItemNumberHighLight from "../../../components/HighLight/ItemNumberHighLight";
import Box from "@mui/material/Box";
import TreeDataIcon from "@oddadigitalsystem/datatable/assets/TreeDataIcon";
import { useTranslation } from "react-i18next";

const TableComponent = ({ projectTitle, data }) => {
  const { t } = useTranslation();
  return (
    <DataTable
      container="div"
      title={projectTitle}
      stickyTopValue={document.getElementById("app-bar")?.clientHeight || 64}
      enableSearch
      initialColumns={[
        { name: t("Task"), key: "task", disableSorting: true },
        {
          name: t("Item"),
          key: "item",
          format: (v) => <ItemNumberHighLight>{v}</ItemNumberHighLight>,
          formatSearch: (v) => v,
          disableSorting: true,
        },
        {
          name: t("Description"),
          key: "description",
          format: (v, row) => {
            const boxSpacing = row.level * 3;
            return (
              <>
                {boxSpacing ? (
                  <Box pl={boxSpacing - 3}>
                    <TreeDataIcon />
                  </Box>
                ) : null}
                <Box pl={boxSpacing}>{v}</Box>
              </>
            );
          },
          disableSorting: true,
        },
        { name: t("Quantity"), key: "quantity", disableSorting: true },
        { name: t("Part"), key: "part", disableSorting: true },
        { name: t("Drawing"), key: "drawing", disableSorting: true },
        { name: t("Serial"), key: "serial", disableSorting: true },
        { name: t("Make/Buy"), key: "make_buy", disableSorting: true },
        {
          name: t("To order"),
          key: "to_order",
          format: (v) => v || <span style={{ color: "red" }}>No Order</span>,
          formatSearch: (v) => v || "No Order",
          disableSorting: true,
        },
        {
          name: t("From order"),
          key: "from_order",
          disableSorting: true,
        },
      ]}
      rows={data.rows}
    />
  );
};

export default TableComponent;
