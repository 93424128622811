import React, { useEffect, useState } from "react";
import moment from "moment";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import "./ProductionTimeline.css";
import { Fade, ListItemText, Box, Card } from "@mui/material";
import ProductionTimelineFilters from "./ProductionTimelineFilters";
import operationStyles from "../../../components/operationStyles";
import authHeaders from "../../../lib/authHeaders";
import { fetchUrl } from "@oddadigitalsystem/vts-components/utils";
import AppBarButtonWrapper from "../../../components/AppBarButtonWrapper";

const ProductionTimeline = () => {
  const routingClasses = operationStyles();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: [
      { id: 0, text: "Simulated" },
      { id: 1, text: "Planned" },
      { id: 2, text: "Firm Planned" },
      { id: 3, text: "Released" },
    ],
    routing_status: [],
    machine_center: [],
    work_center: [],
    item_number: [],
    available: [],
  });
  const [operations, setOperations] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, "w"));

  let chosenMachineCenters = [
    ...filters.machine_center,
    ...filters.work_center,
  ].filter((e) => e);

  const styleOperations = (items) =>
    items.map((op) => ({
      ...op,
      itemProps: {
        onDoubleClick: () =>
          window.open(
            fetchUrl("/production/operations", {
              prod_order: op.production_order,
              operation: op.operation,
            }),
            "_blank"
          ),
        className: routingClasses[op.routing_status.replace(" ", "_")],
      },
    }));

  const filterAvailable = (operations) => {
    switch (filters.available[0]?.id) {
      case 1:
        return operations.filter((o) => o.available);
      case 0:
        return operations.filter((o) => !o.available);
      default:
        return operations;
    }
  };

  const fetchData = () => {
    if (chosenMachineCenters.length > 0) {
      setLoading(true);
      fetch(
        fetchUrl(
          `${process.env.REACT_APP_VTS_API}/v1/production/operations/list`,
          {
            machine_center: chosenMachineCenters.map((e) => e.id),
            status: filters.status.map((e) => e.id),
            routing_status: filters.routing_status.map((e) => e.id),
            item_number: filters.item_number.map((e) => e.id),
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            available: filters.available[0]?.id ?? undefined,
          }
        ),
        {
          headers: authHeaders(),
        }
      )
        .then((r) => r.json())
        .then(({ operations }) => {
          setOperations(() => styleOperations(filterAvailable(operations)));
          setLoading(false);
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchData(), [startDate, endDate, JSON.stringify(filters)]);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading}>
        <ProductionTimelineFilters
          onFiltersChange={(newFilters) => setFilters(newFilters)}
        />
      </AppBarButtonWrapper>
      {chosenMachineCenters.length > 0 ? (
        <Card variant="outlined">
          <Timeline
            defaultTimeStart={startDate}
            defaultTimeEnd={endDate}
            groups={chosenMachineCenters.map((e) => ({ ...e, title: e.text }))}
            items={operations}
            canResize={false}
            canChangeGroup={false}
            stackItems
            onBoundsChange={(canvasTimeStart, canvasTimeEnd) => {
              setStartDate(moment(canvasTimeStart));
              setEndDate(moment(canvasTimeEnd));
            }}
            sidebarWidth={250}
            onItemMove={(id, newTime) => {
              let operation = operations.find((op) => op.id === id);
              // TODO: change state before doing the fetching and rollback if the fetch was unsuccessful.
              fetch(
                fetchUrl(
                  `${process.env.REACT_APP_VTS_API}/v1/production/operations/change_date`,
                  {
                    production_order: operation.production_order,
                    operation: operation.operation,
                    date: moment(newTime).toISOString(),
                  }
                ),
                {
                  headers: authHeaders(),
                }
              ).then(() => fetchData());
            }}
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => {
              const { left: leftResizeProps, right: rightResizeProps } =
                getResizeProps();
              return (
                <div {...getItemProps(item.itemProps)}>
                  {itemContext.useResizeHandle ? (
                    <div {...leftResizeProps} />
                  ) : (
                    ""
                  )}

                  <div
                    className="rct-item-content"
                    style={{ maxHeight: `${itemContext.dimensions.height}` }}
                  >
                    {itemContext.title}
                  </div>

                  {itemContext.useResizeHandle ? (
                    <div {...rightResizeProps} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          >
            <TimelineMarkers>
              <TodayMarker>
                {({ styles, date }) => (
                  <Box
                    sx={{
                      ...styles,
                      backgroundColor: "primary.main",
                    }}
                  />
                )}
              </TodayMarker>
            </TimelineMarkers>
          </Timeline>
        </Card>
      ) : (
        <Fade in>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <ListItemText
              style={{ textAlign: "center" }}
              primary={
                !loading ? "No machine or work center selected" : "Loading..."
              }
              secondary={
                !loading ? "Click the filter button at the top of the page" : ""
              }
            />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default ProductionTimeline;
