import { Box, useMediaQuery, useTheme, Fade } from "@mui/material";

import { Skeleton } from "@mui/material";

const skeletonWidths = ["90%", "80%", "60%", "40%"];

export const WeldText = ({ title, value, loading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display={isMobile ? "block" : "flex"}
      alignItems="center"
      mb={isMobile ? 1 : undefined}
    >
      <Box flex={isMobile ? 0 : 1}>
        <span style={{ fontWeight: 500 }}>
          {typeof title === "string" ? `${title}:` : title}
        </span>
      </Box>
      <Box flex={isMobile ? 0 : 1}>
        {loading ? (
          <Skeleton
            width={
              skeletonWidths[Math.floor(Math.random() * skeletonWidths.length)]
            }
          />
        ) : (
          <Fade in timeout={500}>
            <span>{value}</span>
          </Fade>
        )}
      </Box>
    </Box>
  );
};
