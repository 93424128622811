import { QueryStringParams } from "../../../lib/iframeUtils/types";

const getFilterKeyName = (key: string): string => key.split(/filter\[|]/)[1];

export const getFiltersFromQueryString = (
  params: QueryStringParams
): QueryStringParams =>
  Object.keys(params)
    .filter((key) => key.includes("filter"))
    .reduce((previousValues: QueryStringParams, filterKey: string) => {
      previousValues[getFilterKeyName(filterKey)] = params[filterKey];
      return previousValues;
    }, {});
