import React from "react";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AppsIcon from "@mui/icons-material/Apps";
import DescriptionIcon from "@mui/icons-material/Description";
import HighLight from "./HighLight";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ErrorIcon from "@mui/icons-material/Error";

const ItemNumberHighLight = (props) => <HighLight {...props} />;

ItemNumberHighLight.defaultProps = {
  choices: [
    {
      name: "Lessons Learned",
      url: "/items/lessons-learned",
      parameter: "item_number",
      icon: <AppsIcon />,
    },
    {
      name: "Inventory",
      url: "/items/inventory",
      parameter: "item_number",
      icon: <AllInboxIcon />,
    },
    {
      name: "Documents",
      url: "/documents/documents",
      parameter: "item_number",
      icon: <DescriptionIcon />,
    },
    {
      name: "BOM",
      url: "/items/bom",
      parameter: "item_number",
      icon: <AccountTreeIcon />,
    },
    {
      name: "Routing",
      url: "/items/routing",
      parameter: "routing_number",
      icon: <BuildIcon />,
    },
    {
      name: "Weld Maps",
      url: "/reports/weld-maps",
      parameter: "item_number",
      icon: <FlashOnIcon />,
    },
    {
      name: "NCRs",
      url: "/ncr/list",
      parameter: "item_number",
      icon: <ErrorIcon />,
      extraParams: "&start-date=&end-date=",
    },
  ],
};

export default ItemNumberHighLight;
