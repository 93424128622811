import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFile from "@mui/icons-material/CloudUpload";
import { fetchUrl } from "@oddadigitalsystem/vts-components/utils";
import authHeaders from "../../../lib/authHeaders";

const AddItemsToDocument = ({ documentId, itemNumbers, project }) => {
  const [status, setStatus] = useState(0);

  const buildParams = () => {
    let params = { item_number: itemNumbers };
    if (project) {
      params = { ...params, project: [project], active: [project] };
    }
    return params;
  };

  const updateDocument = () => {
    setStatus(1);
    fetch(
      fetchUrl(
        `${process.env.REACT_APP_VTS_API}/documents/${documentId}/add_tags/`,
        buildParams()
      ),
      {
        headers: authHeaders(),
        method: "PUT",
      }
    )
      .then((r) => r.json())
      .then((r) => setStatus(r ? 2 : 3))
      .catch((e) => {
        alert("Error");
        setStatus(3);
      });
  };
  return (
    <>
      {status < 2 && (
        <IconButton
          onClick={updateDocument}
          disabled={status !== 0}
          size="small"
        >
          {status === 0 && <UploadFile />}
          {status === 1 && <CircularProgress size={14} />}
        </IconButton>
      )}
      {status === 2 && <DoneIcon style={{ color: "green" }} />}
      {status === 3 && <CancelIcon style={{ color: "red" }} />}
    </>
  );
};

AddItemsToDocument.propTypes = {
  documentId: PropTypes.string.isRequired,
  itemNumbers: PropTypes.array.isRequired,
};

export default AddItemsToDocument;
