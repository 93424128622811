import authHeaders from "../../lib/authHeaders";

const getOwnerOptions = async (): Promise<Array<String>> =>
  fetch(`${process.env.REACT_APP_VTS_API}/v1/users/list`, {
    headers: authHeaders(),
  }).then((r) => {
    if (r.ok || r.status === 200) return r.json();
    else throw r.statusText;
  });

export default getOwnerOptions;
