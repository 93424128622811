import { alpha, Button, ButtonGroup, Link } from "@mui/material";
import OperationTooltip from "./OperationTooltip";
import statusColors from "./statusColors.json";

const calculateColor = (status, delayed) => {
  if (delayed)
    return {
      bg: statusColors["delayed"],
      border: statusColors["delayed"],
      font: "#FFF",
    };

  switch (status?.toLowerCase()) {
    case "done": // blue
      return {
        bg: statusColors["done"],
        border: statusColors["done"],
        font: "#FFF",
      };
    case "in progress": //green
      return {
        bg: statusColors["in_progress"],
        border: statusColors["in_progress"],
        font: "#FFF",
      };
    case "planned": // gray
      return {
        bg: statusColors["planned"],
        border: statusColors["planned"],
        font: "#FFF",
      };
    case "pre planned": // white
      return { bg: statusColors["pre_planned"], border: "#CCC", font: "#000" }; // white
    case "hold": // red
      return {
        bg: statusColors["hold"],
        border: statusColors["hold"],
        font: "#FFF",
      };

    default:
      return { bg: "#fff", border: "#fff", font: "#000" };
  }
};

const OperationsCell = ({
  routing,
  productionOrderNumber,
  onClick,
  orientation,
}) => {
  return (
    <ButtonGroup
      style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
      }}
      size="small"
      variant="outlined"
      orientation={orientation}
    >
      {routing.map(
        (
          {
            machine_center,
            number,
            status,
            delayed,
            start_date,
            end_date,
            description,
          },
          i
        ) => {
          const url = `/production/operations?operation=${number}&prod_order=${productionOrderNumber}`;
          const backgroundColor = alpha(
            calculateColor(status, delayed)?.bg,
            0.9
          );
          const fontColor = calculateColor(status, delayed)?.font;
          const borderColor = calculateColor(status, delayed)?.border;
          return (
            <OperationTooltip
              backgroundColor={alpha(backgroundColor, 1)}
              fontColor={fontColor}
              borderColor={alpha(fontColor, 0.2)}
              key={i}
              arrow
              title={
                <div style={{ fontWeight: 400 }}>
                  <div style={{ fontWeight: 500 }}>{description}</div>
                  <div>
                    {new Date(start_date).toLocaleDateString() +
                      " - " +
                      new Date(end_date).toLocaleDateString()}
                  </div>
                  <div>{`Order: ${productionOrderNumber}`}</div>
                  <div>{`Operation: ${number}`}</div>
                  <div>
                    <span>{`Status: ${status}`}</span>
                    {delayed && <span>{` (Delayed)`}</span>}
                  </div>
                </div>
              }
            >
              <Button
                style={{
                  backgroundColor: backgroundColor,
                  color: fontColor,
                  borderColor: borderColor,
                  minWidth: 42,
                  marginBottom: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Link
                  style={{ color: "inherit" }}
                  href={url}
                  underline={
                    Boolean(onClick) && Boolean(url) ? "hover" : "none"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(url);
                  }}
                >
                  {machine_center}
                </Link>
              </Button>
            </OperationTooltip>
          );
        }
      )}
    </ButtonGroup>
  );
};
export default OperationsCell;

export const OperationTemplate = ({ orientation }) => (
  <OperationsCell
    orientation={orientation}
    routing={[
      {
        number: "Finished",
        status: "done",
        delayed: false,
      },
      {
        number: "In progress",
        status: "in_progress",
        delayed: false,
      },
      {
        number: "Hold",
        status: "hold",
        delayed: false,
      },

      {
        number: "Planned",
        status: "planned",
        delayed: false,
      },
      {
        number: "Delayed",
        status: "planned",
        delayed: true,
      },

      {
        number: "Pre-planned",
        status: "pre_planned",
        delayed: false,
      },
    ]}
  />
);
