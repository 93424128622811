import PropTypes from "prop-types";
import React from "react";

import DownloadFileButton from "../../../components/DownloadFileButton";
import AppBarButtonWrapper from "../../../components/AppBarButtonWrapper";

const ItemsToReceiveButtons = ({ filters, loading }) => {
  return (
    <AppBarButtonWrapper showSpinner={loading}>
      <DownloadFileButton
        loading={loading}
        urlEndpoint={`${process.env.REACT_APP_VTS_API}/v1/purchase/items_to_receive`}
        filename="Items to receive"
        fileExtension="csv"
        filters={filters}
      />
    </AppBarButtonWrapper>
  );
};

ItemsToReceiveButtons.propTypes = {
  // parsed url params
  filters: PropTypes.object,
  loading: PropTypes.bool,
};

export default ItemsToReceiveButtons;
