import authHeaders from "../../lib/authHeaders";
import WeldLogInfo from "../../models/WeldLogInfo";
import { FetchResponse } from "../../types";

const getWeldLogInfo = (id: number): Promise<WeldLogInfo | FetchResponse> =>
  fetch(`${process.env.REACT_APP_VTS_API}/v1/weld_logs/info/${id}`, {
    headers: authHeaders(),
  }).then((res) => {
    if (res.ok || res.status === 404) {
      return res.json();
    } else throw res;
  });

export default getWeldLogInfo;
