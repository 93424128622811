import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/main/App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { lightTheme } from "./config/theme";
import AuthProvider from "./pages/main/AuthProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import Bugsnag from "./config/bugsnag";
import { ErrorPage } from "@oddadigitalsystem/vts-components/core";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./config/i18n";
import "moment/locale/nb";
import { momentLocale as momentLocaleFallbackHelper } from "@oddadigitalsystem/vts-components/utils";

// MUI X Pro
LicenseInfo.setLicenseKey(
  "05472703e0655d1c490b367fafea7df3T1JERVI6NDI5NzcsRVhQSVJZPTE2ODMxODQ3NDQwMDAsS0VZVkVSU0lPTj0x"
);

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const FallbackComponent = () => (
  <ErrorPage onClick={() => window.location.reload()} />
);

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      locale={momentLocaleFallbackHelper()}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <AuthProvider>
              <Provider store={store}>
                <SnackbarProvider maxSnack={3} preventDuplicate>
                  <BrowserRouter>
                    <CssBaseline />
                    <App />
                  </BrowserRouter>
                </SnackbarProvider>
              </Provider>
            </AuthProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
